import { createSlice } from "@reduxjs/toolkit";

const businessCentralFilterSlice = createSlice({
  name: "BusinessCentralFilterSlice",
  initialState: {
    storedFilters: {
      status: "",
      projectManager: {},
      vicePresident: {},
      clientName: {},
    },
  },
  reducers: {
    businessCentralFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
    },
  },
});
export const { businessCentralFilterStore } = businessCentralFilterSlice.actions;

export default businessCentralFilterSlice.reducer;
