import T from "T";
import { format } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";

export const VERSIONS_HISTORY_HEADER = [
  { label: T.HISTORY_VERSION },
  { label: T.SUBMISSION_BY_PM },
  { label: T.SUBMISSION_BY_MARKETING_TEAM },
  { label: T.PM_COMMENT, align: "center" },
  { label: T.MARKETING_TEAM_COMMENT, align: "center" },
  { label: T.PMO_COMMENT, align: "center" },
  { label: T.UPLOADED_BY_PM, align: "center" },
  { label: T.UPLOADED_BY_MT, align: "center" },
];

export const downloadCaseStudyFile = async (response, fileName) => {
  const date = format(new Date(), BACKEND_DATE_FORMAT);
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = fileName ? fileName : `MIS-${date}`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(downloadUrl);
};

export const CASE_STUDY_TABLE_HEADER = [
  { label: T.S_NO },
  { label: T.PROJECT_NAME },
  { label: T.CASE_STUDY_FUNCTIONAL_MANAGER },
  { label: T.CASE_STUDY_PROJECT_MANAGER },
  { label: T.CASE_STUDY_PROJECT_START_DATE },
  { label: T.CASE_STUDY_DUE_DATE },
  // { label: T.CASE_STUDY_LINK, align: "center" },
  // { label: T.DOWNLOAD_FILE, align: "center" },
  { label: T.CASE_STUDY_STATUS, align: "center" },
  { label: T.UPLOADED_BY_PM, align: "center" },
  { label: T.UPLOADED_BY_MT, align: "center" },
  // { label: T.IS_PUBLISHED, align: "center" },
  { label: T.CASE_STUDY_ACTION, align: "center" },
];
