import { Box, Grid, Icon, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Apple from "assets/Apple.png";
import Windows from "assets/Windows.png";

const StyledBox = styled(Box)(() => ({
  margin: "auto",
  width: 50,
  height: 50,
}));

const HardwareGrid = ({ record = {} }) => {
  const keysArray = Object.keys(record);
  return (
    <Grid container justifyContent={"flex-end"}>
      {[
        { text: "Windows", color: "#F8F8FF", icon: Windows },
        { text: "Apple", color: "#E0E0E0", icon: Apple },
      ].map((box, index) => (
        <Box
          key={index}
          sx={{
            height: "80px",
            px: "20px",
            backgroundColor: box.color,
            border: "1px solid #ccc",
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            gap: "15px",
            "&:not(:last-child)": {
              marginRight: { md: 1 },
            },
          }}
        >
          <StyledBox component="img" src={box.icon} />
          <Grid container sx={{ flexDirection: "column", justifyContent: "center" }}>
            {["Laptop", "Desktop"].map((item) => (
              <Grid item sx={{ display: "flex" }} key={item}>
                <Typography fontSize={16} sx={{ fontWeight: "bold", color: "#333" }}>
                  {`${item} :`}
                </Typography>
                <Typography fontSize={16} sx={{ color: "#333", pl: item === "Laptop" ? "19px" : 1 }}>
                  {keysArray.includes(`${box.text}-${item}`) ? record[`${box.text}-${item}`] : 0}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Grid>
  );
};

HardwareGrid.propTypes = {
  record: PropTypes.object,
};

export default HardwareGrid;
