import { createSlice } from "@reduxjs/toolkit";

const hardwareFilterSlice = createSlice({
  name: "HardwareFilterSlice",
  initialState: {
    storedFilters: {
      hardwareType: null,
      manufacturer: null,
      warrantyEndDate: null,
      warrantyStartDate: null,
      workLocation: null,
      status: null,
    },
    storedSearch: "",
    storedStatus: true,
    storedDate: {
      startDate: null,
      endDate: null,
    },
  },
  reducers: {
    hardwareFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      state.storedSearch = action.payload.storedSearch;
      state.storedStatus = action.payload.storedStatus;
      state.storedDate = action.payload.storedDate;
    },
  },
});
export const { hardwareFilterStore } = hardwareFilterSlice.actions;

export default hardwareFilterSlice.reducer;
