import noop from "lodash/noop";
import PropTypes from "prop-types";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import T from "T";

import MISTextField from "./MISTextField";
import WithInputLabel from "./WithInputLabel";

const MISDatePicker = ({
  label = "",
  disabled,
  required,
  deprecatedLabel = true,
  name = "",
  endIcon,
  error = false,
  placeholder = T.SELECT,
  format = "MM/DD/YYYY",
  value = {},
  outlined = {},
  minDate = "",
  width = "",
  handleChange = noop,
  ...rest
}) => (
  <WithInputLabel label={deprecatedLabel && label} required={required} endIcon={endIcon} error={error}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat={format}
        value={value}
        minDate={minDate}
        disabled={disabled}
        onChange={(value) => handleChange(value, name)}
        PopperProps={{ placement: "auto-end" }}
        {...rest}
        renderInput={(params) => (
          <MISTextField
            size="small"
            fullWidth={width ? false : true}
            {...params}
            sx={{
              width: { width },
              backgroundColor: "background.white",
              "& .MuiOutlinedInput-input": {
                fontSize: 14,
              },
              ...outlined,
            }}
            inputProps={{
              ...params.inputProps,
              placeholder,
            }}
          />
        )}
      />
    </LocalizationProvider>
  </WithInputLabel>
);

MISDatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.object,
  onHandleDateChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  deprecatedLabel: PropTypes.bool,
  endIcon: PropTypes.node,
  error: PropTypes.bool,
  minDate: PropTypes.object,
  handleChange: PropTypes.func,
};

export default MISDatePicker;
