import PropTypes from "prop-types";

import SalesRepresentativeTable from "./SalesRepresentativeTable";
import AddEditSalesRepresentative from "./AddEditSalesRepresentative";

const SalesRepresentativeDialog = ({
  isSalesRepresentativeOpen,
  columns,
  salesRepresentativeList,
  handleCloseSalesRepresentative,
  openAddEditModal,
  handleDeleteSalesRepresentative,
  isAddEditModalOpen,
  salesRepresentativeName,
  addEditSalesRepresentative,
  departmentsList,
  onChangeSalesRepresentative,
  handleSubmitSalesRepresentative,
  handleCloseAddEditModal,
}) => {
  return (
    <>
      <SalesRepresentativeTable
        columns={columns}
        isSalesRepresentativeOpen={isSalesRepresentativeOpen}
        salesRepresentativeList={salesRepresentativeList}
        handleClose={handleCloseSalesRepresentative}
        openAddEditModal={openAddEditModal}
        handleDeleteSalesRepresentative={handleDeleteSalesRepresentative}
      />
      <AddEditSalesRepresentative
        isAddEditModalOpen={isAddEditModalOpen}
        salesRepresentativeName={salesRepresentativeName}
        addEditSalesRepresentative={addEditSalesRepresentative}
        departmentsList={departmentsList}
        onHandleChange={onChangeSalesRepresentative}
        onHandleSubmit={handleSubmitSalesRepresentative}
        handleClose={handleCloseAddEditModal}
      />
    </>
  );
};

SalesRepresentativeDialog.propTypes = {
  isSalesRepresentativeOpen: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  salesRepresentativeList: PropTypes.array.isRequired,
  handleCloseAddEditModal: PropTypes.func.isRequired,
  openAddEditModal: PropTypes.func.isRequired,
};

export default SalesRepresentativeDialog;
