import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getManagerByDept: builder.query({
      query: ({ departmentId }) => ({
        url: `/projects/managers?departmentId=${departmentId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetManagerByDeptQuery } = extendedApi;
