import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateSalesRepresentative: builder.mutation({
      query: ({ requestDto }) => ({
        url: `/salesRepresentative/update`,
        body: requestDto,
        method: "PUT",
      }),
    }),
  }),
});

export const { useUpdateSalesRepresentativeMutation } = extendedApi;
