import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { TableRow, TableCell, Typography, TableBody, styled, Button, Tooltip, Grid } from "@mui/material";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import T from "T";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const CSVersionTableBody = ({ allTableRows = [], downloadVersionFile = noop }) => {
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const { id, caseStudyVersion, caseStudySubmissionDateMt, caseStudySubmissionDatePm, pmComment, mtComment, pmoComment } =
            record;
          const fileMT = get(record, "fileMT", "");
          const filePM = get(record, "filePM", "");
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell>{caseStudyVersion}</StyledTableCell>
              <StyledTableCell>{caseStudySubmissionDatePm}</StyledTableCell>
              <StyledTableCell>{caseStudySubmissionDateMt}</StyledTableCell>
              <StyledTableCell>{pmComment}</StyledTableCell>
              <StyledTableCell>{mtComment}</StyledTableCell>
              <StyledTableCell>{pmoComment}</StyledTableCell>
              <StyledTableCell align="center">
                <label htmlFor={`fileOutput_${id}`}>
                  <Tooltip sx={{ display: "flex" }} title={T.DOWNLOAD_FILE} placement="top">
                    <DownloadIcon
                      onClick={() => downloadVersionFile(record.id, "pm", filePM)}
                      fontSize="small"
                      sx={{
                        mr: 1,
                        cursor: "pointer",
                        color: NETSMARTZ_THEME_COLOR,
                      }}
                    />
                  </Tooltip>
                </label>
              </StyledTableCell>
              <StyledTableCell align="center">
                <label htmlFor={`fileOutput_${id}`}>
                  <Tooltip sx={{ display: "flex" }} title={T.DOWNLOAD_FILE} placement="top">
                    <DownloadIcon
                      onClick={() => downloadVersionFile(record.id, "mt", fileMT)}
                      fontSize="small"
                      sx={{
                        mr: 1,
                        cursor: "pointer",
                        color: NETSMARTZ_THEME_COLOR,
                      }}
                    />
                  </Tooltip>
                </label>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

export default CSVersionTableBody;
