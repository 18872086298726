import InterviewQuestions from "./InterviewQues";

const Videointerview = () => {
  return (
    <>
      <InterviewQuestions />
    </>
  );
};

export default Videointerview;
