const APP_PREFIX = "/app";

const routes = {
  app: {
    login: `${APP_PREFIX}/login`,
    forgotPwd: `${APP_PREFIX}/forgot-password`,
    changePwd: `${APP_PREFIX}/change-password`,
    home: `${APP_PREFIX}/home`,
    dashboard: `${APP_PREFIX}/dashboard`,
    caseStudy: `${APP_PREFIX}/case-study`,
    csHistory: `${APP_PREFIX}/case-study/:id/versions`,
    settings: `${APP_PREFIX}/settings`,
    testimonial: `${APP_PREFIX}/testimonial`,
    help: `${APP_PREFIX}/help`,
    reports: `${APP_PREFIX}/reports`,
    members: `${APP_PREFIX}/members`,
    recommendedMembers: `${APP_PREFIX}/pre-sales/recommended-members`,
    shortlistedMembers: `${APP_PREFIX}/pre-sales/shortlisted-members`,
    scheduleInterview: `${APP_PREFIX}/pre-sales/pitched-members/schedule-interview`,
    scheduleMockInterview: `${APP_PREFIX}/pre-sales/pitched-members/schedule-mock-interview`,
    codeAssessment: `${APP_PREFIX}/pre-sales/pitched-members/code-assessment`,
    interviewChecklist: `${APP_PREFIX}/interview-checklist`,
    pitchedMembers: `${APP_PREFIX}/pre-sales/pitched-members`,
    allPitchedMembers: `${APP_PREFIX}/pre-sales/interview-tracker`,
    presalesReportCard: `${APP_PREFIX}/pre-sales/report-card`,
    viewClient: `${APP_PREFIX}/pre-sales/view-client`,
    editInterviewTracker: `${APP_PREFIX}/pre-sales/interview-tracker/:id/edit`,
    addMembers: `${APP_PREFIX}/member/add`,
    addConsultant: `${APP_PREFIX}/member/consultant/add`,
    feedback: `${APP_PREFIX}/member/feedback/:id`,
    feedbackForm: `${APP_PREFIX}/member/feedback/form/:id`,
    swotAnalysis: `${APP_PREFIX}/member/swot-analysis/:id`,
    swotForm: `${APP_PREFIX}/member/swot-analysis/form/:id`,
    editSwotForm: `${APP_PREFIX}/member/swot-analysis/form/edit/:id`,
    specialIncidentRegister: `${APP_PREFIX}/member/special-incident-register/:id`,
    createNewProject: `${APP_PREFIX}/pre-sales/project-add`,
    editPreSalesProject: `${APP_PREFIX}/pre-sales/:id/edit`,
    editMembers: `${APP_PREFIX}/members/:id/edit`,
    viewMembers: `${APP_PREFIX}/members/:id`,
    nonCompliance: `${APP_PREFIX}/non-compliance`,
    roles: `${APP_PREFIX}/roles`,
    addRoles: `${APP_PREFIX}/role/add`,
    addSubRoles: `${APP_PREFIX}/subrole/add`,
    editRoles: `${APP_PREFIX}/role/edit/:id`,
    masterSettings: `${APP_PREFIX}/master-settings`,
    preSales: `${APP_PREFIX}/pre-sales`,
    profile: `${APP_PREFIX}/profile`,
    test: `${APP_PREFIX}/test`,
    accounting: `${APP_PREFIX}/accounting`,
    training: `${APP_PREFIX}/training`,
    groups: `${APP_PREFIX}/training/groups`,
    addCourse: `${APP_PREFIX}/training/add-course`,
    editCourse: `${APP_PREFIX}/training/:id/edit-course`,
    traineeCourse: `${APP_PREFIX}/training/:id/traineeCourse`,
    insideSales: `${APP_PREFIX}/inside-sales`,
    memberAssignments: `${APP_PREFIX}/member-assignments/:id`,
    addAssignment: `${APP_PREFIX}/add-assignment/:id`,
    assignedTopics: `${APP_PREFIX}/assigned-topics`,
    mockInterview: `${APP_PREFIX}/mock-interview`,
    mockInterviewReponses: `${APP_PREFIX}/mock-interview-responses`,
    mockAssessment: `${APP_PREFIX}/mock-assessment`,
    mockAssessmentResult: `${APP_PREFIX}/mock-assessment/assessment-result`,
    isTools: `${APP_PREFIX}/is-tools`,
    addISLicense: `${APP_PREFIX}/is-tools/add-license`,
    editISLicense: `${APP_PREFIX}/is-tools/:id/edit-license`,
    referrals: `${APP_PREFIX}/recommendations`,
    referral: `${APP_PREFIX}/referral`,
    refer: `${APP_PREFIX}/refer`,
    referralRecommendationForm: `${APP_PREFIX}/recommendations/form`,
    recommendationDetails: `${APP_PREFIX}/recommendations/recommendation-details`,
    employeeProfile: `${APP_PREFIX}/employeeprofile`,
    technicalInterviewUserPage: `${APP_PREFIX}/technical-interview`,
    technicalInterview: `${APP_PREFIX}/technical-interview/:id`,
    recommendationReportCard: `${APP_PREFIX}/recommendations/report`,
    businessCentral: `${APP_PREFIX}/business-central`,
    addProject: `${APP_PREFIX}/business-central/add-project`,
    editProject: `${APP_PREFIX}/business-central/:id/edit-project`,
    vendors: `${APP_PREFIX}/vendors`,
    vendorsForm: `${APP_PREFIX}/vendors/form`,
    resource: `${APP_PREFIX}/resource`,
    resourceForm: `${APP_PREFIX}/resource/form`,
    hardware: `${APP_PREFIX}/hardware`,
    addHardware: `${APP_PREFIX}/hardware/add`,
    editHardware: `${APP_PREFIX}/hardware/:id/edit`,
    intervieweeLogin: `${APP_PREFIX}/interviewee-login`,
    screenTest: `${APP_PREFIX}/screen-test`,
  },
};

export default routes;
