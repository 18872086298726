import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateProject1: builder.mutation({
      query: ({ requestDto }) => ({
        url: `/businessCentral/update`,
        body: requestDto,
        method: "PUT",
      }),
    }),
  }),
});

export const { useUpdateProject1Mutation } = extendedApi;
