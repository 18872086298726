import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteResourceById: builder.query({
      query: ({ id }) => ({
        url: `/resource/delete/resource?id=${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useLazyDeleteResourceByIdQuery } = extendedApi;
