import api from "api";
const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitVideoResponse: build.mutation({
      query: (formData) => ({
        url: `/videoInterview/save/videoInterviewOneQA`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const { useSubmitVideoResponseMutation } = extendedApi;
