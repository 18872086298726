import { Checkbox, Grid, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISTextField from "components/common/MISTextField";
import usePMFetch from "hooks/usePMFetch";
import React, { Children, useReducer } from "react";
import { get } from "utils/lodash";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISFooterButton from "components/common/MISFooterButton";
import ConfirmSubmit from "./ConfirmSubmit";
import ConfirmCancel from "./ConfirmCancel";
import { useNavigate } from "react-router-dom";
import { MISCurrentUser } from "utils/validations";
import { useAddMemberMutation } from "api/members/addMember";
import { startCase } from "lodash";
import { handleError } from "utils/error";
import { toast } from "react-toastify";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { format, isValid } from "date-fns";
import MISNestedAutocomplete from "components/common/MISNestedAutocomplete";

const ConsultantForm = () => {
  const navigate = useNavigate();
  const { user } = MISCurrentUser();
  const { projectManagers, departmentList, skillList } = usePMFetch();
  const userName = user.user.userName;
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    fullName: "",
    totalExpAsOnDate: "",
    technologyDetails: [
      {
        skillId: "",
        skillRating: 0,
        isPrimary: false,
        isSecondary: true,
      },
    ],
    openConfirmCancel: false,
    openConfirmSubmit: false,
    submitAnyway: false,
  });
  const { fullName, totalExpAsOnDate, technologyDetails, openConfirmCancel, openConfirmSubmit, submitAnyway } = localState;
  const skillListResults = get(skillList, "results", []);
  const deptListResults = get(departmentList, "results", []);
  const projectManagerResults = get(projectManagers, "results", []);
  const selectedDept = deptListResults.find((dept) => dept.departmentName === "BYT") || {};
  const selectedPM = projectManagerResults.find((pm) => pm.name === userName) || {};
  const expYear = totalExpAsOnDate.split(".")[0];
  const expMonth = totalExpAsOnDate.split(".")[1];
  const roundOffYear = parseInt(expYear) + (expMonth >= 6 ? 1 : 0);
  const [addMember, { isFetching }] = useAddMemberMutation();

  const emptyTechDetails = {
    skillId: "",
    skillRating: 0,
    isPrimary: false,
    isSecondary: true,
  };
  const formatData = (list) => {
    const nestedList = [];
    for (let item of list) {
      if (!item.skillName.includes("-")) {
        const newItem = { ...item, children: [] };
        nestedList.push(newItem);
        const ind = nestedList.length - 1;
        let prefix = item.skillName + "-";
        for (let item2 of list) {
          if (item2.skillName.includes(prefix) && item2.skillName !== prefix) nestedList[ind].children.push(item2);
        }
      }
    }
    return nestedList;
  };
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (index, type, value) => {
    technologyDetails[index][type] = value;
    setLocalState({ technologyDetails });
  };

  const onHandleTechChange = (index, event) => {
    const { name, value } = event.target;
    technologyDetails[index][name] = name === "skillRating" ? parseFloat(value) : value;
    setLocalState({ technologyDetails });
  };

  const onHandleCheckboxChange = (index, event) => {
    const { name, checked } = event.target;
    technologyDetails[index][name] = checked;
    technologyDetails[index].isSecondary = !checked;
    setLocalState({ technologyDetails });
  };

  const onHandleTechAddMore = () => {
    technologyDetails.push(emptyTechDetails);
    setLocalState({ technologyDetails });
  };

  const onHandleTechRemove = (index) => {
    technologyDetails.splice(index, 1);
    setLocalState({ technologyDetails });
  };

  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };

  const handleClose = () => {
    navigate("/app/members");
  };

  const handleSubmit = () => {
    const payload = getPayload();
    const filteredPayload = Object.keys(payload)
      .filter((f) => payload[f])
      .reduce((r, i) => {
        r[i] = payload[i];
        return r;
      }, {});

    handleAdd(filteredPayload);
  };

  const isValidate = () => {
    return (
      fullName && fullName.trim() !== "" && totalExpAsOnDate !== null && technologyDetails.every((item) => item.skillId !== "")
    );
  };

  const handleAdd = (payload) => {
    if (isValidate()) {
      addMember(payload)
        .unwrap()
        .then(() => {
          toast.success(T.USER_CREATED_SUCCESSFULLY);
          handleClose();
        })
        .catch(handleError);
    } else {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
    }
  };

  const userSkills = technologyDetails.filter((data) => data.skillId !== "");
  const getPayload = () => {
    const payload = {
      userName: startCase(fullName),
      expInYears: roundOffYear.toString(),
      expInMonths: "0",
      userSkills,
      reportingManager: selectedPM.id,
      departmentId: selectedDept.id,
      empMode: T.CONSULTANT,
      workingDays: [T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY],
      internalJiraExemption: true,
      joiningDate: format(new Date(), BACKEND_DATE_FORMAT),
    };
    if (userSkills.length > 0) {
      payload.userSkills = userSkills;
    }
    return payload;
  };
  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.FULL_NAME}
              required
              fullWidth
              placeholder={T.FULL_NAME}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="fullName"
              value={fullName}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.TOTAL_EXP_AS_ON_DATE}
              fullWidth
              required
              placeholder={T.TOTAL_EXP_AS_ON_DATE}
              size="small"
              variant="outlined"
              name="totalExpAsOnDate"
              value={totalExpAsOnDate}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item xs={12}>
            {Children.toArray(
              technologyDetails.map((data, index) => (
                <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                  <Grid item md={3} xs={12}>
                    <MISNestedAutocomplete
                      label={`${T.TECHNOLOGY} ${T.NAME}`}
                      listDetails={formatData(
                        skillListResults.filter((skill) => !technologyDetails.some((tech) => skill.id === tech.skillId)),
                      )}
                      value={skillListResults.find((skill) => skill.id === technologyDetails[index].skillId) || {}}
                      getByLabelText={(option) => get(option, "skillName", "")}
                      onHandleChange={(event, newValue) =>
                        onHandleAutoCompleteChange(index, "skillId", get(newValue, "id", ""), T.SKILL)
                      }
                      childId="id"
                      childName="skillName"
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Typography variant="body" component="legend" color="text.label">
                      {T.RATING}
                    </Typography>
                    <Rating
                      name="skillRating"
                      precision={0.5}
                      value={technologyDetails[index].skillRating}
                      sx={{ mt: 1.2 }}
                      onChange={(event) => onHandleTechChange(index, event)}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Typography variant="body" component="legend" color="text.label">
                      {T.PRIMARY_SKILL}
                    </Typography>
                    <Checkbox
                      size="small"
                      sx={{ ml: 3 }}
                      name="isPrimary"
                      checked={technologyDetails[index].isPrimary}
                      onChange={(event) => onHandleCheckboxChange(index, event)}
                    />
                  </Grid>

                  <Grid item md={1} xs={12}>
                    {technologyDetails.length === 1 ? (
                      <AddCircleOutlineIcon
                        onClick={onHandleTechAddMore}
                        fontSize="small"
                        sx={{
                          mt: 3.5,
                          cursor: "pointer",
                          color: NETSMARTZ_THEME_COLOR,
                        }}
                      />
                    ) : technologyDetails.length - 1 === index ? (
                      <>
                        <RemoveCircleOutlineIcon
                          onClick={() => onHandleTechRemove(index)}
                          fontSize="small"
                          sx={{
                            mt: 3.5,
                            cursor: "pointer",
                            color: NETSMARTZ_THEME_COLOR,
                          }}
                        />
                        <AddCircleOutlineIcon
                          onClick={onHandleTechAddMore}
                          fontSize="small"
                          sx={{
                            mt: 3.5,
                            cursor: "pointer",
                            color: NETSMARTZ_THEME_COLOR,
                          }}
                        />
                      </>
                    ) : (
                      <RemoveCircleOutlineIcon
                        onClick={() => onHandleTechRemove(index)}
                        fontSize="small"
                        sx={{
                          mt: 3.5,
                          cursor: "pointer",
                          color: NETSMARTZ_THEME_COLOR,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              )),
            )}
          </Grid>
        </Grid>
      </Box>
      <MISFooterButton
        proceedButtonText={T.SUBMIT}
        justify="end"
        sx={{ pb: 0.5 }}
        handleClose={confrmCancelModal}
        handleSubmit={handleSubmit}
      />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
    </>
  );
};

export default ConsultantForm;
