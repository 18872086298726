import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { get } from "lodash";
import PropTypes from "prop-types";

const GiftGrid = ({ giftCount = [] }) => {
  const giftTypes = {
    Big: { text: "Big Gifts", color: "#faa116" },
    Medium: { text: "Medium Gifts", color: "#2d86de" },
    Small: { text: "Small Gifts", color: "#3db76d" },
    Card: { text: "Cards", color: "#ffc312" },
  };

  const giftList = Object.keys(giftTypes).map((type) => ({
    text: giftTypes[type].text,
    number: get(
      giftCount.find((i) => i.giftType === type),
      "count",
      0,
    ),
    color: giftTypes[type].color,
  }));
  return (
    <Grid container justifyContent="space-between" sx={{ py: "5px" }}>
      {giftList.map((box, index) => (
        <Grid
          xs={12}
          md={11.5 / giftList.length}
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 4,
            height: "70px",
            alignItems: "center",
            backgroundColor: box.color,
            border: "1px solid #ccc",
            borderRadius: 2,
          }}
        >
          <Typography fontSize={16} color={"#ffffff"} mr={1}>
            {box.text}
          </Typography>
          <Typography fontSize={25} sx={{ fontWeight: "bold", color: "#ffffff" }}>
            <span>{box.number}</span>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
GiftGrid.propTypes = {
  record: PropTypes.object,
};
export default GiftGrid;
