import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getResourcesByVendorId: builder.query({
      query: ({ page, size, vendorId }) => ({
        url: `/vendor/get/all/resource/vendorsId?page=${page}&size=${size}&vendorId=${vendorId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetResourcesByVendorIdQuery } = extendedApi;
