import React, { useEffect } from "react";
import { Grid, Paper, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISFooterButton from "components/common/MISFooterButton";
import MISTextField from "components/common/MISTextField";
import usePMFetch from "hooks/usePMFetch";
import _, { get } from "lodash";
import { useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AVAILABILITY, VENDOR_COST } from "components/Members/Member/memberModel";
import MISDatePicker from "components/common/MISDatePicker";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useAddResourceMutation } from "api/Vendors/Resource/addResource";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { isMobileNo, isNumberNegative } from "utils/validations";
import { useLazyGetResourceByIdQuery } from "api/Vendors/Resource/getResourceById";
import { useUpdateResourceMutation } from "api/Vendors/Resource/updateResource";
import { ArrowBack } from "@mui/icons-material";
import { ERROR, SUCCESS } from "theme/colors";
import { useLazyGetManagerByDeptQuery } from "api/Vendors/Resource/getMangerByDept";

const ResourceForm = () => {
  const { skillList, departmentList } = usePMFetch();

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    name: "",
    projectName: "",
    status: "",
    accountManager: [],
    accountManagerList: [],
    localDept: [],

    csmRating: "",
    techRating: "",
    BehaviourRating: "",
    overallRating: "",
    startDate: null,
    endDate: null,
    resourceContact: "",
    payment: "",
    netDue: null,
    NoticePeriod: "",
    vednorCost: "",
    clientHourAlloc: "",
    actual: "",
    commitment: "",
    techStack: [],
    comment: "",
    vendorCurrency: "",
  });

  const navigate = useNavigate();
  const { id, vendorId } = useParams();

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const onHandleDateChange = (newValue, type) => {
    if (newValue !== null) {
      const validDate = new Date(newValue);
      if (isValid(validDate)) {
        setLocalState({ [type]: getBEDateFormat(validDate) });
      }
    } else {
      setLocalState({ [type]: null });
    }
  };

  const {
    name,
    projectName,
    localDept,
    status,
    accountManager,

    csmRating,
    techRating,
    BehaviourRating,

    startDate,
    endDate,
    resourceContact,
    payment,
    netDue,
    NoticePeriod,
    vednorCost,
    clientHourAlloc,
    actual,
    commitment,
    techStack,
    comment,
    vendorCurrency,
    accountManagerList,
  } = localState;

  const handleValidation = () => {
    return (
      _.isEmpty(name ? name.trim() : name) ||
      _.isEmpty(projectName ? projectName.trim() : projectName) ||
      _.isEmpty(localDept) ||
      _.isEmpty(accountManager) ||
      _.isEmpty(status) ||
      _.isEmpty(startDate) ||
      _.isEmpty(endDate) ||
      !isMobileNo(resourceContact) ||
      _.isEmpty(netDue) ||
      _.isEmpty(NoticePeriod ? NoticePeriod.trim() : NoticePeriod) ||
      isNumberNegative(NoticePeriod) ||
      _.isEmpty(vednorCost ? vednorCost.trim() : vednorCost) ||
      isNumberNegative(vednorCost) ||
      _.isEmpty(clientHourAlloc ? clientHourAlloc.trim() : clientHourAlloc) ||
      isNumberNegative(clientHourAlloc) ||
      _.isEmpty(actual ? actual.trim() : actual) ||
      isNumberNegative(actual) ||
      _.isEmpty(commitment ? commitment.trim() : commitment) ||
      _.isEmpty(techStack) ||
      _.isEmpty(vendorCurrency) ||
      _.isEmpty(payment ? payment.trim() : payment) ||
      isNumberNegative(payment)
    );
  };

  const [addResourceById] = useAddResourceMutation();
  const [getResourceById] = useLazyGetResourceByIdQuery();
  const [updateResourceById] = useUpdateResourceMutation();
  const [getManagerByDeptId] = useLazyGetManagerByDeptQuery();

  useEffect(() => {
    if (id) {
      getResource();
    }
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(localDept)) {
      getManagerByDept();
    }
  }, [localDept]);

  const getManagerByDept = () => {
    getManagerByDeptId({ departmentId: localDept.id })
      .unwrap()
      .then((res) => {
        if (res) {
          setLocalState({ accountManagerList: res });
        }
      })
      .catch(handleError);
  };

  const getResource = () => {
    getResourceById({ id })
      .unwrap()
      .then((res) => {
        if (res) {
          const prevState = {
            name: res.resourceName,
            projectName: res.projectName,
            status: res.status,
            accountManager: res.projectManager,
            localDept: res.department,
            csmRating: res.csmRating,
            techRating: res.techRating,
            BehaviourRating: res.behaviourRating,

            startDate: res.startDate,
            endDate: res.endDate,
            resourceContact: res.contactNumber,
            payment: res.payment.toString(),
            netDue: res.nextDue,
            NoticePeriod: res.noticePeriodTime,
            vednorCost: res.vendorCost.toString(),
            clientHourAlloc: res.clientHours.toString(),
            actual: res.actualHourAllocated.toString(),
            commitment: res.commitment,
            techStack: res.techStack, //set lateer
            comment: res.comments,
            vendorCurrency: res.vendorCurrency.toString(),
          };
          setLocalState(prevState);
        }
      })
      .catch(handleError);
    //working
  };

  const handleSubmit = () => {
    const payload = {
      resourceName: name,
      projectName: projectName,
      status: status,
      projectManagerId: accountManager.id,
      departmentId: localDept.id,
      csmRating: csmRating,
      techRating: techRating,
      behaviourRating: BehaviourRating,
      startDate: startDate,
      endDate: endDate,
      contactNumber: resourceContact,
      payment: payment,
      nextDue: netDue,
      noticePeriodTime: NoticePeriod,
      vendorCurrency: vendorCurrency,
      vendorCost: vednorCost,
      clientHours: clientHourAlloc,
      actualHourAllocated: actual,
      commitment: commitment,
      techStack: techStack.map((it) => it.id),
      comments: comment,
      vendorPocId: {
        id: vendorId,
      },
    };
    if (id) {
      updateResourceById({ id, payload })
        .unwrap()
        .then(() => {
          toast.success(T.RESOUCE_UPDATE_SUCCESSFULLY, { delay: 500 });
          navigate(-1);
        })
        .catch(handleError);
    } else {
      addResourceById({ payload })
        .unwrap()
        .then(() => {
          toast.success(T.RESOURCE_ADDED_SUCCESSFULLY, { delay: 500 });
          navigate(-1);
        })
        .catch(handleError);
    }
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (newValue, item) => {
    setLocalState({ [item]: newValue });
  };

  return (
    <Paper display={"block"} sx={{ p: 2, borderRadius: 2 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <ArrowBack fontSize="small" sx={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {id ? T.EDIT + " " + T.RESOURCE : T.ADD_RESOURCE}
        </Typography>
      </Box>
      <Grid
        sx={{
          height: "calc(100vh - 250px)",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} fontWeight={600}>
              {T.RESOURCE + " " + T.DETAILS}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.NAME}
              required
              fullWidth
              placeholder={T.NAME}
              size="small"
              variant="outlined"
              name="name"
              value={name}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.PROJECT_NAME}
              required
              fullWidth
              placeholder={T.PROJECT_NAME}
              size="small"
              variant="outlined"
              name="projectName"
              value={projectName}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.CONTACT_INFO}
              InputProps={{ type: "number" }}
              required
              fullWidth
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom: resourceContact && `3px solid ${isMobileNo(resourceContact) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              placeholder={T.CONTACT_INFO}
              size="small"
              variant="outlined"
              name="resourceContact"
              value={resourceContact}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.TECH_STACK}
              required
              multiple
              listDetails={get(skillList, "results", [])}
              value={techStack}
              name={"techStack"}
              getByLabelText={(option) => get(option, "skillName", "")}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "techStack")}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.DEPARTMENT}
              required
              listDetails={get(departmentList, "results", [])}
              value={localDept}
              name={"localDept"}
              getByLabelText={(option) => get(option, "departmentName", "")}
              onHandleChange={(event, newValue) => {
                setLocalState({ accountManagerList: [], accountManager: [] });

                onHandleAutoCompleteChange(newValue, "localDept");
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.MANAGER}
              disabled={_.isEmpty(accountManagerList)}
              required
              listDetails={accountManagerList}
              value={accountManager}
              name={"accountManager"}
              getByLabelText={(option) => get(option, "name", "")}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "accountManager")}
            />
          </Grid>
          {/* <Grid item md={4} xs={12}>
            <MISTextField
              label={T.ACCOUNT_MANAGER + " " + T.EMAIL}
              required
              fullWidth
              placeholder={T.ACCOUNT_MANAGER + " " + T.EMAIL}
              size="small"
              variant="outlined"
              name="email"
              value={email}
              onChange={onHandleChange}
            />
          </Grid> */}

          <Grid item md={4} xs={12}>
            <Typography fontSize={16} fontWeight={500}>
              {"CSM " + T.RATING}
            </Typography>

            <Rating precision={0.5} value={parseFloat(csmRating)} name="csmRating" onChange={onHandleChange} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography fontSize={16} fontWeight={500}>
              {"Tech " + T.RATING}
            </Typography>

            <Rating precision={0.5} value={parseFloat(techRating)} name="techRating" onChange={onHandleChange} />
          </Grid>

          <Grid item md={4} xs={12}>
            <Typography fontSize={16} fontWeight={500}>
              {"Behaviour " + T.RATING}
            </Typography>

            <Rating precision={0.5} value={parseFloat(BehaviourRating)} name="BehaviourRating" onChange={onHandleChange} />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.STATUS}
              required
              multiple={false}
              listDetails={AVAILABILITY}
              name={"status"}
              value={status}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "status")}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISDatePicker
              label={T.START_DATE}
              placeholder={T.START_DATE}
              inputFormat="MM/DD/YYYY"
              value={startDate}
              required
              name="startDate"
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISDatePicker
              label={T.END_DATE}
              placeholder={T.END_DATE}
              inputFormat="MM/DD/YYYY"
              value={endDate}
              required
              name="endDate"
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.PAYMENT}
              required
              fullWidth
              placeholder={T.PAYMENT}
              InputProps={{ type: "number" }}
              size="small"
              variant="outlined"
              name="payment"
              value={payment}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISDatePicker
              label={T.NEXT_DUE}
              placeholder={T.NEXT_DUE}
              inputFormat="MM/DD/YYYY"
              value={netDue}
              required
              name="netDue"
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.NOTICE_PERIOD}
              required
              fullWidth
              placeholder={T.NOTICE_PERIOD + " In Days"}
              InputProps={{ type: "number" }}
              size="small"
              variant="outlined"
              name="NoticePeriod"
              value={NoticePeriod}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.VENDOR + " Currency"}
              required
              multiple={false}
              listDetails={VENDOR_COST}
              name={"vendorCurrency"}
              value={vendorCurrency}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "vendorCurrency")}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.VENDOR + " Cost"}
              required
              fullWidth
              placeholder={T.VENDOR + " Cost"}
              InputProps={{ type: "number" }}
              size="small"
              variant="outlined"
              name="vednorCost"
              value={vednorCost}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.CLIENT_HOUR_ALLOC}
              required
              fullWidth
              placeholder={T.CLIENT_HOUR_ALLOC}
              InputProps={{ type: "number" }}
              size="small"
              variant="outlined"
              name="clientHourAlloc"
              value={clientHourAlloc}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.ACTUAL_HOUR}
              required
              fullWidth
              placeholder={T.ACTUAL_HOUR}
              InputProps={{ type: "number" }}
              size="small"
              variant="outlined"
              name="actual"
              value={actual}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.COMMITMENT}
              required
              fullWidth
              placeholder={T.COMMITMENT}
              size="small"
              variant="outlined"
              name="commitment"
              value={commitment}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.COMMENTS}
              multiline
              fullWidth
              placeholder={T.COMMENTS}
              size="small"
              variant="outlined"
              name="comment"
              value={comment}
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box display="flex" flex={1} justifyContent="flex-end" alignItems={"center"} mt={2}>
        <MISFooterButton
          proceedButtonText="Submit"
          disableProceed={handleValidation()}
          handleClose={() => {
            navigate(-1);
          }}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Paper>
  );
};

export default ResourceForm;
