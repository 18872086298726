import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    saveSalesRepresentative: builder.mutation({
      query: ({ requestDto }) => ({
        url: `/salesRepresentative/save`,
        body: requestDto,
        method: "POST",
      }),
    }),
  }),
});

export const { useSaveSalesRepresentativeMutation } = extendedApi;
