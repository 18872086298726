import { useState } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { Box, IconButton, useTheme, Menu, MenuItem, Typography } from "@mui/material";

import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";

import { handleLogout } from "utils/logout";
import T from "T";
import { get } from "lodash";

const Circle = ({ number }) => {
  return (
    <div className="circle">
      <Box
        sx={{
          width: 15,
          height: 15,
          mx: 1,
          backgroundColor: NETSMARTZ_THEME_COLOR,
          color: BACKGROUND.white,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          fontSize: 10,
        }}
      >
        {number}
      </Box>
    </div>
  );
};

const MenuButton = ({
  iconType = "",
  items = [],
  handleAssignDialog = noop,
  handleEditAssignDialog = noop,
  handleAssignedCourseDialog = noop,
  handleAssignHardwareDialog = noop,
  handleEditHardwareDialog = noop,
  handleDeactivateDialog = noop,
  handleRevokeResignationDialog = noop,
  handleDeleteUserDialog = noop,
  handleRemoveRoleDialog = noop,
  handleAssignMockInterview = noop,
  handleTechAssignment = noop,
  handleAssignTechnicalInterview = noop,
  id = "",
  isHandleLogout = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (title, route) => {
    handleCloseNavMenu();
    switch (title) {
      case T.LOGOUT:
        return handleLogout();
      case T.ASSIGN_ROLE:
        return handleAssignDialog();
      case T.EDIT_ROLE:
        return handleEditAssignDialog();
      case T.ASSIGN_COURSE:
        return handleAssignedCourseDialog();
      case T.ASSIGN_HARDWARE:
        return handleAssignHardwareDialog();
      case T.EDIT_HARDWARE:
        return handleEditHardwareDialog();
      case T.DEACTIVATE:
        return handleDeactivateDialog();
      case T.REVOKE:
        return handleRevokeResignationDialog();
      case T.DELETE:
        return handleDeleteUserDialog();
      case T.ASSIGN_MOCK_INTERVIEW:
        return handleAssignMockInterview();
      case T.TECHNICAL_INTERVIEW:
        return handleAssignTechnicalInterview();
      case T.TECH_ASSIGNMENT:
        return handleTechAssignment();
      default:
        return id ? navigate(route.replace(":id", id)) : navigate(route);
    }
  };

  const Wrapper = iconType;

  return (
    <Box>
      <IconButton aria-label="account" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}>
        {Wrapper}
      </IconButton>
      <Menu
        sx={{
          zIndex: theme.zIndex.modal + 3,
          "& .MuiMenu-list": {
            pt: 0,
            pb: 0,
            minWidth: 150,
            maxHeight: 300, // Set the maximum height for the menu
            overflowY: "auto", // Enable vertical scrolling
          },

          "& .MuiMenuItem-root": {
            "&:hover": {
              "& .circle .MuiBox-root": {
                backgroundColor: BACKGROUND.white,
                color: NETSMARTZ_THEME_COLOR,
              },
              backgroundColor: NETSMARTZ_THEME_COLOR,
              color: BACKGROUND.white,

              img: {
                filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(254deg) brightness(106%) contrast(101%)",
              },
            },
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElNav}
        open={Boolean(anchorElNav)}
        keepMounted
        onClose={handleCloseNavMenu}
      >
        {items.map(({ icon, title, permission, route, badgeContent }, i) => {
          if (!permission) {
            return false;
          }
          return (
            <MenuItem divider={i < items.length - 1} key={title} sx={{ pl: 2 }} onClick={() => handleNavigate(title, route)}>
              {icon && <Box component="img" src={icon} margin="auto 0px" pl={1} pr={1} width={18} height={18} />}
              <Typography variant="subtitle1">{title}</Typography>
              {badgeContent && badgeContent !== 0 ? <Circle number={badgeContent} /> : null}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

MenuButton.propTypes = {
  iconType: PropTypes.object,
  items: PropTypes.array,
  isHandleLogout: PropTypes.bool,
  id: PropTypes.number,
  handleAssignDialog: PropTypes.func,
  handleAssignedCourseDialog: PropTypes.func,
  handleDeactivateDialog: PropTypes.func,
  handleRevokeResignationDialog: PropTypes.func,
  handleAssignMockInterview: PropTypes.func,
  handleDeleteUserDialog: PropTypes.func,
  handleRemoveRoleDialog: PropTypes.func,
  handleEditAssignDialog: PropTypes.func,
  handleTechAssignment: PropTypes.func,
  handleAssignTechnicalInterview: PropTypes.func,
};

export default MenuButton;
