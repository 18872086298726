import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, Checkbox } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import T from "T";
import { MISCurrentUser } from "utils/validations";
import { noop } from "lodash";

const GiftTableHeader = ({ columns = [], allSelected = bool, handleSelectAll = noop }) => {
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  return (
    <TableHead>
      <TableRow sx={{ height: 20 }}>
        {Children.toArray(
          columns
            .filter((item) => userRole === T.COO || item.label !== T.PROPOSED_GIFT)
            .map((column, ind) => {
              const columnName = get(column, "label", "").toUpperCase();
              return column.key === "checkBox" ? (
                <TableCell
                  key={column.key}
                  sx={{
                    border: "none",
                    backgroundColor: BACKGROUND.header,
                    paddingY: 0,
                  }}
                >
                  <Checkbox checked={allSelected} onClick={(e) => handleSelectAll(e)} />
                </TableCell>
              ) : (
                <TableCell
                  align={get(column, "align", "")}
                  key={column.key}
                  sx={{
                    border: "none",
                    backgroundColor: BACKGROUND.header,
                    paddingY: 0,
                  }}
                >
                  <Typography variant="body1" fontSize={14} noWrap fontWeight={625}>
                    {columnName}
                  </Typography>
                </TableCell>
              );
            }),
        )}
      </TableRow>
    </TableHead>
  );
};

export default GiftTableHeader;
