import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import T from "T";

const TableHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow sx={{ height: 20 }}>
      {Children.toArray(
        columns.map((column, ind) => {
          const columnName = get(column, "label", "").toUpperCase();
          return (
            <TableCell
              sx={{
                border: "none",
                backgroundColor: BACKGROUND.header,
                paddingY: 0,
              }}
            >
              <Typography variant="body1" fontSize={13} noWrap fontWeight={600}>
                {columnName}
              </Typography>
            </TableCell>
          );
        }),
      )}
    </TableRow>
  </TableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(TableHeader);
