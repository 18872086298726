import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, Tooltip, IconButton, Grid } from "@mui/material";
import { get, noop } from "lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { TitleTypography } from "utils/members";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import T from "T";
import { Children, useReducer } from "react";
import MISTextField from "components/common/MISTextField";
import MISDatePicker from "components/common/MISDatePicker";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import MISAutocomplete from "components/common/MISAutocomplete";
import usePMFetch from "hooks/usePMFetch";
import { getFEDateFormat } from "utils/members";
import { AddCircle, Edit, Save } from "@mui/icons-material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 5px 8px 5px",
  textAlign: "center",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const ResourceTableBody = ({
  usersList,
  rowIndex,
  activeColumn,
  getAllResourseList,
  setActiveInput,
  addNewRow,
  removeRow,
  onHandleTableChange,
  onHandleTableDateChange,
  onHandleTableAutoComplete,
}) => {
  const renderAddDeleteIcons = (index) => (
    <>
      {(index > 0 || getAllResourseList.length > 1) && (
        <IconButton
          onClick={(e) => removeRow(index)}
          sx={{
            color: NETSMARTZ_THEME_COLOR,
          }}
        >
          <Tooltip title={T.DELETE} placement="top">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      )}
      {index === getAllResourseList.length - 1 && (
        <IconButton
          onClick={(e) => addNewRow()}
          sx={{
            color: NETSMARTZ_THEME_COLOR,
          }}
        >
          <Tooltip title={T.ADD} placement="top">
            <AddCircle />
          </Tooltip>
        </IconButton>
      )}
    </>
  );
  const renderEditSaveIcons = (index) =>
    index === rowIndex ? (
      <IconButton
        onClick={(e) => setActiveInput(-1, null)}
        sx={{
          color: NETSMARTZ_THEME_COLOR,
        }}
      >
        <Tooltip title={T.SAVE} placement="top">
          <Save />
        </Tooltip>
      </IconButton>
    ) : (
      <IconButton
        onClick={(e) => setActiveInput(index)}
        sx={{
          color: NETSMARTZ_THEME_COLOR,
        }}
      >
        <Tooltip title={T.EDIT} placement="top">
          <Edit />
        </Tooltip>
      </IconButton>
    );

  return (
    <TableBody sx={{ position: "relative" }}>
      {getAllResourseList.map((record, index) => {
        const {
          userId,
          startDate,
          endDate,
          // technology,
          monthlySpendHour,
          actualHoursSpent,
          resourceType,
          noticePeriod,
          costClient,
          invoiceDate,
          comment,
        } = record;
        return (
          <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
            <StyledTableCell
              sx={{
                borderLeft: rowIndex === index ? `3px solid ${NETSMARTZ_THEME_COLOR}` : "",
                borderRadius: rowIndex === index ? "5px 0px 0px 5px" : "",
                width: rowIndex === index ? "350px" : "auto",
                position: "sticky",
                left: "0px",
                zIndex: +1,
                backgroundColor: "inherit",
              }}
              onClick={(e) => setActiveInput(index, e)}
            >
              {rowIndex === index ? (
                <MISAutocomplete
                  name={"userId"}
                  sx={{ width: "340px" }}
                  listDetails={usersList}
                  getByLabelText={(option) => get(option, "userEmailId", "")}
                  value={usersList.find((item) => item.id === userId) || {}}
                  onHandleChange={(e, newValue) => onHandleTableAutoComplete("userId", get(newValue, "id", ""), index)}
                />
              ) : (
                <TitleTypography>
                  {get(
                    usersList.find((item) => item.id === userId),
                    "userName",
                    "",
                  )}
                </TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {rowIndex === index ? (
                <MISDatePicker
                  name="startDate"
                  width="143px"
                  format="DD/MM/YYYY"
                  value={startDate}
                  handleChange={(newValue, type) => onHandleTableDateChange(newValue, type, index)}
                  renderInput={(params) => <MISTextField {...params} />}
                />
              ) : (
                <TitleTypography>{getFEDateFormat(startDate)}</TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {rowIndex === index ? (
                <MISDatePicker
                  name="endDate"
                  width="143px"
                  format="DD/MM/YYYY"
                  value={endDate}
                  handleChange={(newValue, type) => onHandleTableDateChange(newValue, type, index)}
                  renderInput={(params) => <MISTextField {...params} />}
                />
              ) : (
                <TitleTypography>{getFEDateFormat(endDate)}</TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {rowIndex === index ? (
                <MISTextField
                  name="monthlySpendHour"
                  value={monthlySpendHour}
                  size="small"
                  sx={{ width: "auto" }}
                  variant="outlined"
                  onChange={(e) => onHandleTableChange(e, index)}
                />
              ) : (
                <TitleTypography>{get(record, "monthlySpendHour", "-")}</TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {rowIndex === index ? (
                <MISTextField
                  name="actualHoursSpent"
                  value={actualHoursSpent}
                  size="small"
                  variant="outlined"
                  sx={{ width: "auto" }}
                  onChange={(e) => onHandleTableChange(e, index)}
                />
              ) : (
                <TitleTypography>{get(record, "actualHoursSpent", "-")}</TitleTypography>
              )}
            </StyledTableCell>
            {/* <StyledTableCell>
              {rowIndex === index ? (
                <MISAutocomplete
                // name={"user"}
                // sx={{ width: "340px" }}
                // listDetails={usersList}
                // getByLabelText={(option) => get(option, "userEmailId", "")}
                // value={usersList.find((item) => item.id === get(user, "id", "")) || {}}
                // onHandleChange={(e, newValue) => onHandleTableAutoComplete("user", newValue, index)}
                />
              ) : (
                <TitleTypography>{resourceType}</TitleTypography>
              )}
            </StyledTableCell> */}
            <StyledTableCell>
              {rowIndex === index ? (
                <MISTextField
                  name="noticePeriod"
                  value={noticePeriod}
                  size="small"
                  sx={{ width: "100px" }}
                  variant="outlined"
                  onChange={(e) => onHandleTableChange(e, index)}
                />
              ) : (
                <TitleTypography>{noticePeriod}</TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {rowIndex === index ? (
                <MISTextField
                  name="costClient"
                  value={costClient}
                  type="number"
                  size="small"
                  sx={{ width: "auto" }}
                  variant="outlined"
                  onChange={(e) => onHandleTableChange(e, index)}
                />
              ) : (
                <TitleTypography>{costClient}</TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {rowIndex === index ? (
                <MISDatePicker
                  name="invoiceDate"
                  width="143px"
                  format="DD/MM/YYYY"
                  value={invoiceDate}
                  handleChange={(newValue, type) => onHandleTableDateChange(newValue, type, index)}
                  renderInput={(params) => <MISTextField {...params} />}
                />
              ) : (
                <TitleTypography>{getFEDateFormat(invoiceDate)}</TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {rowIndex === index ? (
                <MISTextField
                  name="comment"
                  value={comment}
                  size="small"
                  sx={{ width: "150px" }}
                  multiline
                  rows={2}
                  variant="outlined"
                  onChange={(e) => onHandleTableChange(e, index)}
                />
              ) : (
                <TitleTypography>{comment}</TitleTypography>
              )}
            </StyledTableCell>
            <StyledTableCell sx={{ position: "sticky", right: "0px", zIndex: +1, backgroundColor: "inherit" }}>
              <Grid display={"inline-flex"}>
                {renderEditSaveIcons(index)}
                {renderAddDeleteIcons(index)}
              </Grid>
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};

export default ResourceTableBody;
