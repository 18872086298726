import { Box, Grid, styled, Tooltip } from "@mui/material";
import MISButton from "components/common/MISButton";
import SearchBar from "components/Members/SearchBar";
import { noop } from "lodash";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import T from "T";
import { canAddHardware } from "utils/permissions";
import { GET_SIZE } from "utils/responsive";
import HardwareFilters from "../HardwareFilters";
import MISDatePicker from "components/common/MISDatePicker";
import CSV from "assets/CSV.png";
import UploadingTool from "../HardwareUploadTool";

const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  tabValue = "",
  searchInput = "",
  handleKeyChange = noop,
  handleChange = noop,
  isFilterEmpty = false,
  filters = {},
  onhandleFilterChange = noop,
  onHandleDateFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
  purchaseStartDate = null,
  purchaseEndDate = null,
  onHandleDateChange = noop,
  handleAssignHardwareDialog = noop,
  handleExport = noop,
  bulkHardware = "",
  handleBulkFileUpload = noop,
  handleDeleteFile = noop,
  handleBulkUploadClose = noop,
  handleSubmitUpload = noop,
}) => {
  const { isLg } = GET_SIZE();
  const navigate = useNavigate();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        px: "16px",
        py: 1,
        backgroundColor: "background.card",
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={2.5} xs={12}>
        <SearchBar searchInput={searchInput} handleKeyChange={handleKeyChange} handleChange={handleChange} />
      </Grid>
      <Grid item md={2} xs={12}>
        <MISDatePicker
          placeholder={tabValue === "1" ? T.PURCHASE_START_DATE : T.ASSIGN_START_DATE}
          inputFormat="DD/MM/YYYY"
          // value={purchaseStartDate && getFEDateFormat(purchaseStartDate)}
          value={purchaseStartDate}
          name="purchaseStartDate"
          // minDate={dayjs(minDate)}
          maxDate={purchaseEndDate}
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      -
      <Grid item md={2} xs={12}>
        <MISDatePicker
          placeholder={tabValue === "1" ? T.PURCHASE_END_DATE : T.ASSIGN_END_DATE}
          inputFormat="DD/MM/YYYY"
          // value={purchaseEndDate && getFEDateFormat(purchaseEndDate)}
          value={purchaseEndDate}
          name="purchaseEndDate"
          minDate={purchaseStartDate}
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={2.5} xs={12}>
        <HardwareFilters
          tabValue={tabValue}
          isFilterEmpty={isFilterEmpty}
          filters={filters}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          onHandleDateFilterChange={onHandleDateFilterChange}
        />
      </Grid> 
      {tabValue === "1" && canAddHardware() && (
        <Grid item md={"auto"} display="flex" justifyContent="flex-end">
          <Box display="flex" alignItems="center">
            <UploadingTool
              bulkHardware={bulkHardware}
              handleBulkFileUpload={handleBulkFileUpload}
              handleDeleteFile={handleDeleteFile}
              handleSubmitUpload={handleSubmitUpload}
              handleBulkUploadClose={handleBulkUploadClose}
            />
            <Tooltip title={T.CSV} placement="top">
              <StyledBox component="img" src={CSV} onClick={() => handleExport()} />
            </Tooltip>
          </Box>
        </Grid>
      )}
      {tabValue === "1" && canAddHardware() && (
        <Grid item md={"auto"} xs={12} container justifyContent="flex-end">
          <MISButton size="medium" onClick={() => navigate(routes.app.addHardware)}>
            {T.ADD_HARDWARE}
          </MISButton>
        </Grid>
      )}
      {tabValue === "2" && canAddHardware() && (
        <Grid item md={2} xs={12} container justifyContent="flex-end">
          <MISButton size="medium" onClick={() => handleAssignHardwareDialog()}>
            {T.ASSIGN_HARDWARE}
          </MISButton>
        </Grid>
      )}
    </Grid>
  );
};

export default TopBar;
