import { useEffect, useReducer } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { initial, noop } from "lodash";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import { GIFT_TYPE, OCCASION_LIST } from "../businessCentralModel";
import { GET_SIZE } from "utils/responsive";
import MISTextField from "components/common/MISTextField";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISButton from "components/common/MISButton";
import { useGetClientGiftsMutation } from "api/BusinessCentral/getClientGifts";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { handleError } from "utils/error";
import { useSaveSpocGiftMutation } from "api/BusinessCentral/saveSpocGift";
import { MISCurrentUser } from "utils/validations";
import { get } from "utils/lodash";

const PresentGiftModal = ({ isPresentGiftOpen = false, spocName = "", spocId = "", handleClose = noop, refreshData = noop }) => {
  const { isMd, isLg } = GET_SIZE();
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const initialState = { occasion: "", customOccasion: "", giftType: "", giftHistory: [] };
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), initialState);
  const { occasion, customOccasion, giftType, giftHistory } = localState;
  const [getClientGifts] = useGetClientGiftsMutation();
  const [saveSpocGift] = useSaveSpocGiftMutation();

  useEffect(() => {
    const giftsFilterRequestDto = {
      statuses: [4],
      spocId: spocId,
      spocFilter: true,
    };
    isPresentGiftOpen &&
      getClientGifts({ page: 0, rowsPerPage: 10000, giftsFilterRequestDto })
        .unwrap()
        .then((res) => {
          const { allTableRows } = getCurrentTableParams(res);
          setLocalState({ giftHistory: allTableRows });
        })
        .catch(handleError);
  }, [isPresentGiftOpen]);

  const spocOccasions = [...new Set(giftHistory.map((item) => item.occasion))];
  const occasionOptionList =
    giftHistory.length === 0 ? OCCASION_LIST : OCCASION_LIST.filter((item) => !spocOccasions.includes(item));

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  };
  const onHandleAutocompleteChange = (type, newValue) => {
    setLocalState({ [type]: newValue });
  };

  const onHandleClose = () => {
    setLocalState({ occasion: "", customOccasion: "", giftType: "", giftHistory: [] });
    handleClose();
  };

  const validateForm = () => {
    if (!occasion) return false;

    if (occasion === T.OTHER && !customOccasion) return false;

    if (!giftType) return false;

    return true;
  };

  const handlePresentGift = () => {
    const submitStatus = userRole === T.COO ? 4 : 2;
    const requestDto = [
      {
        spocId: spocId,
        status: "Active",
        giftStatus: submitStatus,
        giftType: giftType,
        occasion: occasion !== T.OTHER ? occasion : customOccasion,
      },
    ];
    saveSpocGift({ requestDto })
      .unwrap()
      .then((res) => {
        onHandleClose();
        refreshData();
      })
      .catch(handleError);
  };

  return (
    <Dialog onClose={() => onHandleClose()} open={isPresentGiftOpen} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={() => onHandleClose()}>
              <ArrowBack />
            </IconButton>
            {spocName && `Present Gift to ${spocName}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minWidth: isLg ? "450px" : isMd ? "300px" : "sm", height: "inherit" }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <MISAutocomplete
              label={T.OCCASION}
              required
              fullWidth
              listDetails={occasionOptionList}
              value={occasion}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutocompleteChange("occasion", newValue)}
            />
          </Grid>
          {occasion === T.OTHER && (
            <Grid item md={6} xs={12}>
              <MISTextField
                label={T.CUSTOM_OCCASION}
                placeholder={T.CUSTOM_OCCASION}
                value={customOccasion}
                required
                autoComplete="on"
                size="small"
                variant="outlined"
                name="customOccasion"
                fullWidth
                onChange={(e) => onHandleChange(e)}
              />
            </Grid>
          )}
          <Grid item md={occasion === T.OTHER ? 12 : 6} xs={12}>
            <MISAutocomplete
              label={T.GIFT_TYPE}
              required
              fullWidth
              listDetails={GIFT_TYPE}
              value={giftType}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutocompleteChange("giftType", newValue)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid display="flex" justifyContent="flex-end" width="100%">
          <Box display="flex" justifyContent="space-between">
            <MISButton variant="outlined" onClick={() => handleClose()}>
              {T.CANCEL}
            </MISButton>
            <MISButton disabled={!validateForm()} variant="contained" sx={{ mx: 2 }} onClick={() => handlePresentGift()}>
              {T.SUBMIT}
            </MISButton>
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PresentGiftModal;
