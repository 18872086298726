import { Children, useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, TableRow, TableCell, Typography, TableBody, Tooltip, styled, IconButton, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubTitleTypography, TitleTypography, StyledContentCopyIcon } from "utils/members";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { capitalizeWords } from "utils/commonFunction";
import T from "T";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { getFEDateFormat } from "utils/members";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 8px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const HardwareTableBody = ({ allTableRows = [] }) => {
  const navigate = useNavigate();
  const handleEditHardware = (id, serialNumber) => {
    navigate(`/app/hardware/${id}/edit`, { state: { serialNumber } });
  };

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const { hardwareID, hardwareType, manufacturer, model, serialNumber, purchaseDate, warrantyEndDate, workLocation } =
            record;
          return (
            <StyledTableRow key={index}>
              <StyledTableCell>{get(hardwareType, "type", "")}</StyledTableCell>
              <StyledTableCell>{get(manufacturer, "name", "")}</StyledTableCell>
              <StyledTableCell>{model}</StyledTableCell>
              <StyledTableCell>{serialNumber}</StyledTableCell>
              <StyledTableCell>{getFEDateFormat(purchaseDate)}</StyledTableCell>
              <StyledTableCell>{getFEDateFormat(warrantyEndDate)}</StyledTableCell>
              <StyledTableCell>{get(workLocation, "workLocationName", "")}</StyledTableCell>
              <StyledTableCell>
                <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleEditHardware(hardwareID, serialNumber)}>
                  <EditIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

export default HardwareTableBody;
