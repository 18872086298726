import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFilterHardware: builder.mutation({
      query: ({ page, rowsPerPage, payload }) => ({
        url: `hardware/filter?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetFilterHardwareMutation } = extendedApi;
