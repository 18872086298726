import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, camelCase } from "lodash";

import { Typography, Divider, Card, Button, Box } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { CASE_STUDY_FILTER_OPTIONS } from "settings/constants/filter";

import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import T from "T";
import { get } from "utils/lodash";
import { MISCurrentUser } from "utils/validations";

const CaseStudyFilterOptions = ({
  filters = {},
  projectList = [],
  projectManagers = [],
  functionalManagers = [],
  handleFilterClose = noop,
  handleFilterApply = noop,
  onhandleFilterChange = noop,
  handleClose = noop,
}) => {
  const CASESTUDY_STATUS_LIST = [T.PENDING, T.SUBMITTED_BY_MT, T.SUBMITTED_BY_PM, T.PUBLISHED, T.PMO_REJECTED, T.PMO_APPROVED];
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const { isXs } = GET_SIZE();

  const occurrenceCount = {};
  for (let proj of projectList) {
    if (occurrenceCount[proj.name]) {
      occurrenceCount[proj.name]++;
    } else {
      occurrenceCount[proj.name] = 1;
    }
  }

  const getFilterRecords = (val) => {
    switch (val) {
      case T.CASESTUDY_PROJECTS:
        return projectList;
      case T.PROJECT_MANAGER:
        return projectManagers;
      case T.FUNCTIONAL_MANAGER:
        return functionalManagers;
      case T.STATUS:
        return CASESTUDY_STATUS_LIST;
      default:
    }
  };

  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;
    const modIndex = [T.PM].includes(userRole) && index === 1 ? 3 : index;
    switch (modIndex) {
      case 0: {
        if (get(option, "name", "")) {
          return occurrenceCount[option.name] === 1
            ? `${option.name}`
            : `${option.name} | ${option.department} | ${option.projectType}`;
        } else {
          return get(option, "name", "");
        }
      }
      case 1:
        return get(option, "name", "");
      case 2:
        return get(option, "name", "");
      case 3:
        return optionVal;
      default:
    }
  };
  const CASE_STUDY_FILTER_OPTIONS = [T.PM].includes(userRole)
    ? [T.CASESTUDY_PROJECTS, T.STATUS]
    : [T.CASESTUDY_PROJECTS, T.PROJECT_MANAGER, T.FUNCTIONAL_MANAGER, T.STATUS];

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "27%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.FILTER}
          </Typography>
          <Divider />

          {Children.toArray(
            CASE_STUDY_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);
              const key = camelCase(item);
              const multiple = [T.CASESTUDY_PROJECTS].includes(item);
              return (
                <Box
                  display="flex"
                  p="10px 20px"
                  justifyContent="space-between"
                  pb={index === CASE_STUDY_FILTER_OPTIONS.length - 1 ? "10px" : "0px"}
                >
                  <Typography variant="subtitle1" noWrap>
                    {item}
                  </Typography>

                  <MISAutocomplete
                    multiple={multiple}
                    // showSelectAll={multiple}
                    listDetails={lists}
                    size="small"
                    limitTags={2}
                    placeholder={T.SELECT}
                    sx={{ width: "200px" }}
                    value={filters[key]}
                    getByLabelText={(option) => getLabelData(option, index)}
                    onHandleChange={(event, newValue) => {
                      onhandleFilterChange(newValue, key);
                    }}
                  />
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterClose}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterApply}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

CaseStudyFilterOptions.propTypes = {
  projectManagers: PropTypes.array,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterApply: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default CaseStudyFilterOptions;
