import { createSlice } from "@reduxjs/toolkit";

const badgeSlice = createSlice({
  name: "BadgeSlice",
  initialState: {
    storedBadge: [],
  },
  reducers: {
    badgeStore: (state, action) => {
      state.storedBadge = action.payload.storedBadge;
    },
  },
});

export const { badgeStore } = badgeSlice.actions;
export default badgeSlice.reducer;
