import { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Grid, Typography, Box, Dialog, DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import { get } from "utils/lodash";
import { format, isValid } from "date-fns";
import MISFooterButton from "components/common/MISFooterButton";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISDialog from "components/common/MISDialog";
import usePMFetch from "hooks/usePMFetch";
import T from "T";
import { PAGINATION } from "settings/constants/pagination";

import { handleError } from "utils/error";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useLazyGetActiveProjectListQuery } from "api/projects/getActiveProjectList";
import { useAddCaseStudyMutation } from "api/CaseStudy/CreateCaseStudy";
import { BACKGROUND } from "theme/colors";

const { INITIAL_PAGE } = PAGINATION;
const ROWS_PER_PAGE = 10000;

const AddEditCaseStudy = ({
  allTableRows = [],
  id = "",
  openAddEditCaseStudy = false,
  editId = "",
  handleAddEditCaseStudyDialog = noop,
  refreshTable = noop,
}) => {
  // const [getProjectById] = useLazyGetProjectByIdQuery();

  const [getActiveProjectList, { data: activeProjectList }] = useLazyGetActiveProjectListQuery();
  const [addCaseStudy] = useAddCaseStudyMutation();

  const projectListDetails = get(activeProjectList, "results", []);
  const occurrenceCount = {};
  for (let proj of projectListDetails) {
    if (occurrenceCount[proj.name]) {
      occurrenceCount[proj.name]++;
    } else {
      occurrenceCount[proj.name] = 1;
    }
  }
  const defaultLocalState = {
    startDate: null,
    dueDate: null,
    projectManager: "",
    project: null,
    caseStudyLink: "",
    disableAlreadyExists: false,
  };

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), defaultLocalState);

  const { dueDate, project, disableAlreadyExists } = localState;
  const { projectManagers } = usePMFetch();

  useEffect(() => {
    getActiveProjectList({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE }).unwrap().catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pMResults = get(projectManagers, "results", []);
  const selectedProjectPM = pMResults.find((res) => res.name === get(project, "projectManager.name", ""));
  const projectStartDate = get(project, "startDate", "");

  // useEffect(() => {
  //   const newDueDate = new Date(projectStartDate);
  //   newDueDate.setMonth(newDueDate.getMonth() + 3);
  //   newDueDate.setDate(newDueDate.getDate() + 15);
  //   const formattedDueDate = newDueDate && isValid(newDueDate) ? getBEDateFormat(newDueDate) : null;
  //   setLocalState({ dueDate: formattedDueDate });
  // }, [projectStartDate]);

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const onHandleDateChange = (newValue, type) => {
    if (newValue !== null) {
      const validDate = new Date(newValue);
      if (isValid(validDate)) {
        setLocalState({ [type]: getBEDateFormat(validDate) });
      }
    } else {
      setLocalState({ [type]: null });
    }
  };

  const onHandleAutoCompleteChange = (type, value) => {
    setLocalState({ [type]: value });
  };

  const resetState = () => {
    setLocalState(defaultLocalState);
  };

  const handleAddEditCaseStudy = () => {
    const payload = {
      projectId: project.id,
      // projectManagerId: project.projectManager.id,
      // projectStartDate: project.startDate,
      caseStudyDueDate: dueDate,
      // caseStudyLink: "",
    };
    addCaseStudy(payload)
      .unwrap()
      .then(() => {
        handleClose();
        toast.success(T.CASE_STUDY_ADDED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  const handleClose = () => {
    resetState();
    handleAddEditCaseStudyDialog();
    refreshTable();
  };

  useEffect(() => {
    const foundObjects = allTableRows?.find((obj) => obj.project.id === project?.id);
    setLocalState({ disableAlreadyExists: false });
    if (foundObjects) {
      toast.error(T.PROJECT_CASESTUDY_ALREADY_EXISTS);
      setLocalState({ disableAlreadyExists: true });
    }
  }, [project]);

  return (
    <Dialog open={openAddEditCaseStudy} onClose={() => handleClose()}>
      <Box textAlign="center" mb={1} pl={1} pt={2} sx={{ backgroundColor: BACKGROUND.cardDefault }}>
        <Typography variant="h6" fontWeight={700} align={"center"}>
          {T.ADD_CASE_STUDY.toUpperCase()}
        </Typography>
      </Box>
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{
            p: 2,
            "& .MuiFormLabel-root": {
              "& .MuiTypography-root": {
                color: "text.popupLabel",
                fontSize: 14,
                fontWeight: 600,
              },
            },
            width: "300px",
          }}
        >
          <Grid item xs={12}>
            <MISAutocomplete
              label={`${T.PROJECT.toUpperCase()} ${T.NAME.toUpperCase()}`}
              required
              listDetails={projectListDetails}
              getByLabelText={(listItem) =>
                occurrenceCount[listItem.name] === 1
                  ? `${listItem.name}`
                  : `${listItem.name} | ${listItem.department} | ${listItem.projectType}`
              }
              value={project && Object.keys(project).length ? project : null}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange("project", newValue)}
            />
          </Grid>

          {/* <Grid item xs={6}>
            <MISAutocomplete
              label={T.PROJECT_MANAGER.toUpperCase()}
              value={selectedProjectPM}
              disabled
              required
              getByLabelText={(option) => get(option, "name", "")}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange("projectManager", get(newValue, "id", ""))}
            />
          </Grid>

          <Grid item xs={6}>
            <MISDatePicker
              label={T.CASE_STUDY_PROJECT_START_DATE.toUpperCase()}
              disabled
              placeholder={T.CASE_STUDY_PROJECT_START_DATE}
              inputFormat="MM/DD/YYYY"
              value={projectStartDate || null}
              required
              name="startDate"
              // handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid> */}

          <Grid item xs={12}>
            <MISDatePicker
              label={T.DUE_DATE.toUpperCase()}
              placeholder={T.DUE_DATE}
              inputFormat="DD/MM/YYYY"
              value={dueDate}
              // disablePast
              name="dueDate"
              handleChange={onHandleDateChange}
              renderInput={(params) => <MISTextField {...params} />}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <MISFooterButton
        proceedButtonText={T.SAVE}
        justify="center"
        size="medium"
        disableProceed={!project || disableAlreadyExists}
        sx={{ p: 1, m: 1 }}
        handleClose={() => {
          handleAddEditCaseStudyDialog();
          resetState();
        }}
        handleSubmit={handleAddEditCaseStudy}
      />
    </Dialog>
  );
};

AddEditCaseStudy.propTypes = {
  id: PropTypes.string,
  allTableRows: PropTypes.array,
  openAddEditCaseStudy: PropTypes.bool,
  editId: PropTypes.string,
  handleAddEditCaseStudyDialog: PropTypes.func,
  refreshTable: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
};

export default AddEditCaseStudy;
