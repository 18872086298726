import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, Tooltip, IconButton, Grid } from "@mui/material";
import memo from "utils/react";
import { get, noop } from "lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { TitleTypography } from "utils/members";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import T from "T";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 17px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const MISTableBody = ({ allTableRows = [], deleteProjectOnId = noop }) => {
  const navigate = useNavigate();

  const handleEditProject = (id, name) => {
    navigate(`/app/business-central/${id}/edit-project`, { state: { projectHeaderName: name } });
  };
  return (
    <TableBody>
      {allTableRows.map((record, index) => {
        return (
          <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
            <StyledTableCell>
              <TitleTypography
                sx={{ textDecoration: "underline", color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                onClick={() => handleEditProject(get(record, "id", ""))}
              >
                {get(record, "projectNumber", "")}
              </TitleTypography>
            </StyledTableCell>
            <StyledTableCell>
              <TitleTypography>{get(record, "projectStatus", "")}</TitleTypography>
            </StyledTableCell>
            <StyledTableCell>
              <Tooltip title={get(record, "projectName", "")} placement="top">
                <TitleTypography>{get(record, "projectName", "")}</TitleTypography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell>
              <TitleTypography>{get(record, "vicePresident", "")}</TitleTypography>
            </StyledTableCell>
            {/* <StyledTableCell>
              <TitleTypography>{get(record, "clientName", "")}</TitleTypography>
            </StyledTableCell>
            <StyledTableCell>
              <TitleTypography>{get(record, "clientEmailId", "")}</TitleTypography>
            </StyledTableCell> */}
            <StyledTableCell>
              <Grid display={"inline-flex"}>
                <IconButton
                  onClick={() => handleEditProject(get(record, "id", ""), get(record, "projectName", ""))}
                  sx={{
                    color: NETSMARTZ_THEME_COLOR,
                  }}
                >
                  <Tooltip title={T.EDIT} placement="top">
                    <Edit />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => deleteProjectOnId(get(record, "id", ""))}
                  sx={{
                    color: NETSMARTZ_THEME_COLOR,
                  }}
                >
                  <Tooltip title={T.REVOKE} placement="top">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};
MISTableBody.propTypes = {
  allTableRows: PropTypes.array,
  deleteProjectOnId: PropTypes.func,
};
export default MISTableBody;
