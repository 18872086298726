import { Grid, IconButton, InputAdornment, Tooltip, Typography, Input, Chip, Box, Button } from "@mui/material";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import { noop } from "lodash";
import { get } from "utils/lodash";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ERROR, NETSMARTZ_THEME_COLOR } from "theme/colors";

const CreateHardware = ({
  hardwareType,
  manufacturer,
  workLocation,
  model,
  serialNumber,
  purchaseDate,
  warrantyDate,
  hdd,
  windowsOs,
  ram,
  poNo,
  seatNumber,
  systemDetails,
  hardwareTypeList = [],
  manufacturerList = [],
  workLocationList = [],
  onHandleChange = noop,
  onHandleAutoComplete = noop,
  onHandleDateChange = noop,
  // handleOpenHardwareTypeDialog,
  handleOpenDialog = noop,
  hardwareDetailsFields,
  invalidField,
}) => {
  const isInvalid = (fieldName) =>
    invalidField?.includes(fieldName) && hardwareDetailsFields.find((item) => item.name === fieldName)?.value;

  return (
    <Grid container spacing={2}>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.HARDWARE_TYPE}
          listDetails={hardwareTypeList}
          required
          getByLabelText={(option) => get(option, "type", "")}
          value={hardwareType}
          sx={{
            ".MuiAutocomplete-popper": { zIndex: 1100 },
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("hardwareType") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => {
            onHandleAutoComplete("hardwareType", newValue);
          }}
          inputAdornment={
            <InputAdornment>
              <Tooltip title={T.ADD_HARDWARE_TYPE} placement="top">
                <IconButton onClick={() => handleOpenDialog("hardwareType")}>
                  <AddCircleOutlineIcon
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          andormentPosition={"endAdornment"}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.MANUFACTURER}
          listDetails={manufacturerList}
          required
          getByLabelText={(option) => get(option, "name", "")}
          value={manufacturer}
          sx={{
            ".MuiAutocomplete-popper": { zIndex: 1100 },
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("manufacturer") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => {
            onHandleAutoComplete("manufacturer", newValue);
          }}
          inputAdornment={
            <InputAdornment>
              <Tooltip title={T.ADD_MANUFACTURER} placement="top">
                <IconButton onClick={() => handleOpenDialog("manufacturer")}>
                  <AddCircleOutlineIcon
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          andormentPosition={"endAdornment"}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.MODEL}
          required
          fullWidth
          placeholder={T.MODEL}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="model"
          value={model}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("model") && `3px solid ${ERROR.main}`,
            },
          }}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.SERIAL_NUMBER}
          required
          fullWidth
          placeholder={T.SERIAL_NUMBER}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="serialNumber"
          value={serialNumber}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("serialNumber") && `3px solid ${ERROR.main}`,
            },
          }}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISDatePicker
          label={T.PURCHASE_DATE}
          placeholder={T.PURCHASE_DATE}
          inputFormat="DD/MM/YYYY"
          value={purchaseDate}
          required
          name="purchaseDate"
          handleChange={onHandleDateChange}
          outlined={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("purchaseDate") && `3px solid ${ERROR.main}`,
            },
          }}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISDatePicker
          label={T.WARRANTY_END_DATE}
          placeholder={T.WARRANTY_END_DATE}
          inputFormat="DD/MM/YYYY"
          value={warrantyDate}
          minDate={purchaseDate}
          required
          name="warrantyDate"
          handleChange={onHandleDateChange}
          outlined={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("warrantyDate") && `3px solid ${ERROR.main}`,
            },
          }}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.WORK_LOCATION}
          listDetails={workLocationList}
          required
          getByLabelText={(option) => get(option, "workLocationName", "")}
          value={workLocation}
          sx={{
            ".MuiAutocomplete-popper": { zIndex: 1100 },
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("workLocation") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => {
            onHandleAutoComplete("workLocation", newValue);
          }}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.HDD}
          fullWidth
          placeholder={T.HDD}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="hdd"
          value={hdd}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.WINDOWS_OS}
          fullWidth
          placeholder={T.WINDOWS_OS}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="windowsOs"
          value={windowsOs}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.RAM}
          fullWidth
          placeholder={T.RAM}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="ram"
          value={ram}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.PURCHASE_ORDER_NO}
          fullWidth
          placeholder={T.PURCHASE_ORDER_NO}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="poNo"
          value={poNo}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.SEAT_NUMBER}
          fullWidth
          placeholder={T.SEAT_NUMBER}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="seatNumber"
          value={seatNumber}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.SYSTEM_DETAILS}
          fullWidth
          placeholder={T.SYSTEM_DETAILS}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="systemDetails"
          value={systemDetails}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
    </Grid>
  );
};

export default CreateHardware;
