import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVendorById: builder.query({
      query: ({ vendorId }) => ({
        url: `/vendor/find/vendor?id=${vendorId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetVendorByIdQuery } = extendedApi;
