import HardwareTable from "./HardwareTable";

const HardwareTab = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  allTableRows = [],
}) => {
  return (
    <HardwareTable
      allTableRows={allTableRows}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

export default HardwareTab;
