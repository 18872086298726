import { useState } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Card, Tooltip, Typography, styled } from "@mui/material";
import T from "T";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import Bulk_Upload from "assets/Bulk_Upload.png";
import UploadCard from "./UploadCard";
const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const UploadingTool = ({
  bulkHardware = "",
  handleBulkFileUpload = noop,
  handleDeleteFile = noop,
  handleSubmitUpload = noop,
  handleBulkUploadClose = noop,
}) => {
  const dispatch = useDispatch();
  const [cardOpen, setCardOpen] = useState(false);

  const handleCardClick = () => {
    setCardOpen(!cardOpen);
  };

  const onCardClose = () => {
    setCardOpen(false);
    handleBulkUploadClose("bulkHardware");
  };

  const onCardSubmit = (bulkType) => {
    handleSubmitUpload(bulkType);
    setCardOpen(false);
    handleBulkUploadClose("bulkHardware");
  };
  return (
    <>
      <Tooltip title={T.UPLOADING_TOOL} placement="top">
        <StyledBox sx={{ height: 35, width: 35 }} component="img" src={Bulk_Upload} pr={1} onClick={() => handleCardClick()} />
      </Tooltip>
      {cardOpen && (
        <UploadCard
          bulkHardware={bulkHardware}
          handleBulkFileUpload={handleBulkFileUpload}
          handleDeleteFile={handleDeleteFile}
          handleSubmitUpload={onCardSubmit}
          handleClose={() => onCardClose()}
        />
      )}
    </>
  );
};

UploadingTool.propTypes = {
  handleBulkFileUpload: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  handleSubmitUpload: PropTypes.func,
  handleBulkUploadClose: PropTypes.func,
};

export default UploadingTool;
