import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getManufacturerList: builder.query({
      query: ({ page, rowsPerPage }) => ({
        url: `/manufacturer/get/all?page=${page}&size=${rowsPerPage}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetManufacturerListQuery } = extendedApi;
