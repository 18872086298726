import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { noop } from "lodash";
import PropTypes from "prop-types";

const HardwareTypeMutation = ({
  addEditHardwareType = {},
  isAddEditHardwareTypeOpen = bool,
  onHandleChange = noop,
  handleClose = noop,
  handleSubmitHardwareType = noop,
}) => {
  return (
    <Dialog onClose={() => handleClose("hardwareType")} open={isAddEditHardwareTypeOpen}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={() => handleClose("hardwareType")}>
              <ArrowBack />
            </IconButton>
            {get(addEditHardwareType, "hardwareTypeHeadName", "")
              ? `${T.EDIT} ${get(addEditHardwareType, "hardwareTypeHeadName", "")}`
              : `${T.ADD} ${T.NEW} ${T.HARDWARE_TYPE}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <MISTextField
          label={T.HARDWARE_TYPE}
          required
          placeholder={T.HARDWARE_TYPE}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="hardwareTypeName"
          value={get(addEditHardwareType, "hardwareTypeName", "")}
          onChange={(e) => onHandleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={addEditHardwareType.hardwareTypeId ? T.UPDATE : T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={() => handleClose("hardwareType")}
          handleSubmit={
            addEditHardwareType.hardwareTypeId
              ? () => handleSubmitHardwareType(addEditHardwareType.hardwareTypeId)
              : () => handleSubmitHardwareType()
          }
        />
      </DialogActions>
    </Dialog>
  );
};

HardwareTypeMutation.propTypes = {
  addEditHardwareType: PropTypes.object,
  isAddEditHardwareTypeOpen: PropTypes.bool,
  onHandleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmitHardwareType: PropTypes.func,
};

export default HardwareTypeMutation;
