import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import T from "T";
import { useAddVendorMutation } from "api/Vendors/addVendor";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISFooterButton from "components/common/MISFooterButton";
import MISTextField from "components/common/MISTextField";
import usePMFetch from "hooks/usePMFetch";
import _, { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useLazyGetVendorByIdQuery } from "api/Vendors/getVendorById";
import { useUpdateVendorMutation } from "api/Vendors/updateVendor";
import { VENDOR_WORK_MODE } from "components/Members/Member/memberModel";
import { isEmail, isGst, isMobileNo, isNumberNegative, isPan } from "utils/validations";
import { ERROR, SUCCESS } from "theme/colors";
import { ArrowBack } from "@mui/icons-material";

const VendorsForm = () => {
  const { skillList } = usePMFetch();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    poc1Name: null,
    poc1Email: "",
    poc1Location: "",
    poc1Phone: "",
    poc1EmpSize: "",
    poc1WorkMode: "",
    poc1Status: "",
    poc2Name: "",
    poc2Email: "",
    poc2Location: "",
    poc2Phone: "",
    poc2Pan: "",
    poc2Gst: "",
    poc2Rating: "",
    poc2Comments: "",
    skills: [],
    // active: false,
  });

  const [addVendor] = useAddVendorMutation();
  const [getVendorByid] = useLazyGetVendorByIdQuery();
  const [updateVendorByid] = useUpdateVendorMutation();

  const navigate = useNavigate();
  const { id } = useParams();

  const handleSubmit = () => {
    const payload = {
      companyName: poc1Name,
      companyEmail: poc1Email,
      location: poc1Location,
      phone: poc1Phone,
      employeeSize: poc1EmpSize,
      techStack: skills.map((it) => it.id),
      workMode: poc1WorkMode,
      status: poc1Status,
      ownerPanNumber: poc2Pan,
      ownerGstNumber: poc2Gst,
      comments: poc2Comments,
      ownerAddress: poc2Location,
      ownerEmail: poc2Email,
      ownerPhone: poc2Phone,
      ownerName: poc2Name,
    };

    if (id) {
      updateVendorByid({ id, payload })
        .unwrap()
        .then(() => {
          toast.success(T.VENDOR_UPDATE_SUCCESS, { delay: 500 });
          navigate(-1);
        })
        .catch(handleError);
    } else {
      addVendor({ payload })
        .unwrap()
        .then(() => {
          toast.success(T.VENDOR_ADD_SUCCESS, { delay: 500 });
          navigate(-1);
        })
        .catch(handleError);
    }
  };

  const getVendor = () => {
    getVendorByid({ vendorId: id })
      .unwrap()
      .then((res) => {
        if (res) {
          setLocalState({
            poc1Name: res.companyName,
            poc1Email: res.companyEmail,
            poc1Location: res.location,
            poc1Phone: res.phone,
            poc1EmpSize: res.employeeSize,

            poc1WorkMode: res.workMode,
            poc1Status: res.status,
            poc2Name: res.ownerName,
            poc2Email: res.ownerEmail,
            poc2Location: res.ownerAddress,
            poc2Phone: res.ownerPhone,
            poc2Pan: res.ownerPanNumber,
            poc2Gst: res.ownerGstNumber,
            poc2Comments: res.comments,
            skills: res.techStack,
            active: res.active,
          });
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (id) {
      getVendor();
    }
  }, [id]);

  const {
    poc1Name,
    poc1Email,
    poc1Location,
    poc1Phone,
    poc1EmpSize,

    poc1WorkMode,
    poc1Status,
    poc2Name,
    poc2Email,
    poc2Location,
    poc2Phone,
    poc2Pan,
    poc2Gst,
    poc2Rating,
    poc2Comments,
    skills,
    // active,
  } = localState;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (newValue, item) => {
    setLocalState({ [item]: newValue });
  };

  const handleValidation = () => {
    return (
      isEmpty(poc1Name ? poc1Name.trim() : poc1Name) ||
      !isEmail(poc1Email ? poc1Email.trim() : poc1Email) ||
      _.isEmpty(poc1Location ? poc1Location.trim() : poc1Location) ||
      !isMobileNo(poc1Phone) ||
      _.isEmpty(poc1EmpSize ? poc1EmpSize.trim() : poc1EmpSize) ||
      isNumberNegative(poc1EmpSize) ||
      _.isEmpty(poc1WorkMode) ||
      _.isEmpty(poc2Name ? poc2Name.trim() : poc2Name) ||
      !isEmail(poc2Email ? poc2Email.trim() : poc2Email) ||
      _.isEmpty(poc2Location ? poc2Location.trim() : poc2Location) ||
      !isMobileNo(poc2Phone) ||
      !isPan(poc2Pan) ||
      !isGst(poc2Gst) ||
      _.isEmpty(skills)
    );
  };

  return (
    <Paper display={"block"} sx={{ p: 2, borderRadius: 2 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <ArrowBack fontSize="small" sx={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {id ? T.EDIT + " " + T.VENDOR : T.ADD_VENDOR}
        </Typography>
      </Box>
      <Grid
        sx={{
          height: "calc(100vh - 250px)",
          overflow: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={16} fontWeight={600}>
              {T.COMPANY_DETAILS}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.COMPANY_NAME}
              required
              fullWidth
              placeholder={T.COMPANY_NAME}
              size="small"
              variant="outlined"
              name="poc1Name"
              value={poc1Name}
              onChange={onHandleChange}
              // sx={{
              //   "& .MuiOutlinedInput-notchedOutline": {
              //     borderBottom: poc1Name && `3px solid ${!_.isEmpty(poc1Name) ? SUCCESS.main : ERROR.main}`,
              //   },
              // }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.LOCATION}
              required
              fullWidth
              placeholder={T.LOCATION}
              size="small"
              variant="outlined"
              name="poc1Location"
              value={poc1Location}
              // sx={{
              //   "& .MuiOutlinedInput-notchedOutline": {
              //     borderBottom: poc1Location && `3px solid ${!_.isEmpty(poc1Location) ? SUCCESS.main : ERROR.main}`,
              //   },
              // }}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.NO_OF_EMPLOYEES}
              InputProps={{ type: "number" }}
              required
              fullWidth
              placeholder={T.NO_OF_EMPLOYEES}
              size="small"
              variant="outlined"
              name="poc1EmpSize"
              value={poc1EmpSize}
              // sx={{
              //   "& .MuiOutlinedInput-notchedOutline": {
              //     borderBottom: poc1EmpSize && `3px solid ${!_.isEmpty(poc1EmpSize) ? SUCCESS.main : ERROR.main}`,
              //   },
              // }}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.PAN}
              required
              fullWidth
              placeholder={T.PAN}
              size="small"
              variant="outlined"
              name="poc2Pan"
              value={poc2Pan}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom: poc2Pan && `3px solid ${isPan(poc2Pan) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.GST}
              required
              fullWidth
              placeholder={T.GST}
              size="small"
              variant="outlined"
              name="poc2Gst"
              value={poc2Gst}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom: poc2Gst && `3px solid ${isGst(poc2Gst) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={16} fontWeight={600}>
              {T.POC + " " + T.DETAILS}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.EMAIL}
              // error={!isEmail(poc1Email)}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom: poc1Email && `3px solid ${isEmail(poc1Email) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              required
              fullWidth
              placeholder={T.EMAIL}
              size="small"
              variant="outlined"
              name="poc1Email"
              value={poc1Email}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.PHONE_NUMBER}
              InputProps={{ type: "number" }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom: poc1Phone && `3px solid ${isMobileNo(poc1Phone) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              // error={!isMobileNo(poc1Phone)}
              required
              fullWidth
              placeholder={T.PHONE_NUMBER}
              size="small"
              variant="outlined"
              name="poc1Phone"
              value={poc1Phone}
              onChange={onHandleChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.WORK_MODE}
              required
              multiple={false}
              listDetails={VENDOR_WORK_MODE}
              value={poc1WorkMode}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "poc1WorkMode")}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.TECH_STACK}
              required
              multiple
              listDetails={get(skillList, "results", [])}
              value={skills}
              getByLabelText={(option) => get(option, "skillName", "")}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "skills")}
            />
          </Grid>

          {/* <Grid item md={4} xs={12}>
            <MISAutocomplete
              label={T.STATUS}
              required
              multiple={false}
              listDetails={EMP_STATUS}
              name={"poc1Status"}
              value={poc1Status}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "poc1Status")}
            />
          </Grid> */}
          {/* <Grid item md={4} xs={12}>
            <FormControl required>
              <FormLabel>{T.ACTIVE}</FormLabel>

              <RadioGroup color={NETSMARTZ_THEME_COLOR} name="active" value={active} onChange={onHandleChange}>
                <FormControlLabel value={true} control={<Radio />} label="True" />
                <FormControlLabel value={false} control={<Radio />} label="False" />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <Typography fontSize={16} fontWeight={600}>
              {T.OWNER + " " + T.DETAILS}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.NAME}
              required
              fullWidth
              placeholder={T.NAME}
              size="small"
              variant="outlined"
              name="poc2Name"
              value={poc2Name}
              // sx={{
              //   "& .MuiOutlinedInput-notchedOutline": {
              //     borderBottom: poc2Name && `3px solid ${!_.isEmpty(poc2Name) ? SUCCESS.main : ERROR.main}`,
              //   },
              // }}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.EMAIL}
              // error={!isEmail(poc2Email)}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom: poc2Email && `3px solid ${isEmail(poc2Email) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              required
              fullWidth
              placeholder={T.EMAIL}
              size="small"
              variant="outlined"
              name="poc2Email"
              value={poc2Email}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.LOCATION}
              required
              fullWidth
              placeholder={T.LOCATION}
              size="small"
              variant="outlined"
              name="poc2Location"
              value={poc2Location}
              // sx={{
              //   "& .MuiOutlinedInput-notchedOutline": {
              //     borderBottom: poc2Location && `3px solid ${!_.isEmpty(poc2Location) ? SUCCESS.main : ERROR.main}`,
              //   },
              // }}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.PHONE}
              // error={!isMobileNo(poc2Phone)}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom: poc2Phone && `3px solid ${isMobileNo(poc2Phone) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              InputProps={{ type: "number" }}
              required
              fullWidth
              placeholder={T.PHONE}
              size="small"
              variant="outlined"
              name="poc2Phone"
              value={poc2Phone}
              onChange={onHandleChange}
            />
          </Grid>

          {/* <Grid item md={4} xs={12}>
            <Typography fontSize={16} fontWeight={300}>
              {T.RATING}
            </Typography>

            <Rating precision={0.5} value={poc2Rating} name="poc2Rating" onChange={onHandleChange} />
          </Grid> */}
          <Grid item md={4} xs={12}>
            <MISTextField
              label={T.COMMENTS}
              multiline
              fullWidth
              placeholder={T.COMMENTS}
              size="small"
              variant="outlined"
              name="poc2Comments"
              value={poc2Comments}
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box display="flex" flex={1} justifyContent="flex-end" alignItems={"center"} mt={2}>
        <MISFooterButton
          proceedButtonText="Submit"
          disableProceed={handleValidation()}
          handleClose={() => {
            navigate(-1);
          }}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Paper>
  );
};

export default VendorsForm;

VendorsForm.prototypes = {
  companyName: PropTypes.string,
  poc1Name: PropTypes.string,
  poc1Email: PropTypes.string,
  poc1Location: PropTypes.string,
  poc1Phone: PropTypes.string,
  poc1EmpSize: PropTypes.string,
  poc1TechStack: PropTypes.string,
  poc1WorkMode: PropTypes.string,
  poc1Status: PropTypes.string,
  poc2Name: PropTypes.string,
  poc2Email: PropTypes.string,
  poc2Location: PropTypes.string,
  poc2Phone: PropTypes.string,
  poc2Pan: PropTypes.string,
  poc2Gst: PropTypes.string,
  poc2Rating: PropTypes.string,
  poc2Comments: PropTypes.string,
  onHandleChange: PropTypes.func,
};
