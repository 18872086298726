import { useEffect, useReducer, useCallback } from "react";
import MISFooterButton from "components/common/MISFooterButton";
import ProjectDetail from "./ProjectDetail/ProjectDetail";
import { Box, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import T from "T";
import ConfirmCancel from "../ConfirmCancel";
import { noop, update } from "lodash";
import { useSaveProject1Mutation } from "api/BusinessCentral/saveProject";
import { useUpdateProject1Mutation } from "api/BusinessCentral/updateProject";
import { useLazyGetProjectById1Query } from "api/BusinessCentral/getProjectById";
import { useLazyGetSalesRepresentativeQuery } from "api/BusinessCentral/SalesRepresentative/getSalesRepresentative";
import { useLazyGetSalesRepresentativeByIdQuery } from "api/BusinessCentral/SalesRepresentative/getSalesRepresentativeById";
import { useSaveSalesRepresentativeMutation } from "api/BusinessCentral/SalesRepresentative/saveSalesRepresentative";
import { useUpdateSalesRepresentativeMutation } from "api/BusinessCentral/SalesRepresentative/updateSalesRepresentative";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { PAGINATION } from "settings/constants/pagination";
import { handleError } from "utils/error";
import usePMFetch from "hooks/usePMFetch";
import { get } from "utils/lodash";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { toast } from "react-toastify";
import SalesRepresentativeDialog from "./SalesRepresentativeDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { useLazyGetAllResourseQuery } from "api/BusinessCentral/getAllResourse";
import { useDeleteSalesrepresentativeMutation } from "api/BusinessCentral/SalesRepresentative/deleteSalesRepresentative";
import ResourseDetail from "./ResourseDetail/ResourseDetail";
import { useLazyGetUserListQuery } from "api/members/getUserList";
import { isEmail, isNumber, isString } from "utils/validations";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import ClientDetail from "./ClientDetail/ClientDetail";
import ProjectEstimate from "./ProjectEstimate/ProjectEstimate";
import { debounce } from "lodash";
import { useLazyGetPreSalesRegionQuery } from "api/preSales/getPreSalesRegions";
import { useLazyGetCountriesQuery } from "api/BusinessCentral/getCountries";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const FULL_ROWS_PER_PAGE = 10000;

const Form = ({ handleClose = noop }) => {
  const location = useLocation();
  const { projectManagers, functionalManagers, departmentList } = usePMFetch();
  const url = location.pathname;
  const id = url.includes("edit") && Number(url.replace(/\D+/g, ""));
  const emptyResource = {
    id: null,
    userId: "",
    startDate: null,
    endDate: null,
    monthlySpendHour: "",
    actualHoursSpent: "",
    resourceType: "",
    noticePeriod: "",
    costClient: "",
    invoiceDate: null,
    comment: "",
  };
  const emptyProjectEstimate = {
    id: "",
    startDate: null,
    originalTimeEstimate: 0,
    totalCost: "",
  };
  const emptyClientDetail = {
    id: null,
    clientName: "",
    clientEmailId: "",
    department: null,
    clientDesignation: "",
    clientBirthday: null,
    clientCountry: "",
    clientGift: false,
    status: true,
    csmConnect: false,
  };
  const initialState = {
    tabValue: "1",
    invalidField: null,
    // Project Details Fields Start
    jiraName: "",
    projectName: "",
    projectType: "",
    department: "",
    projectStatus: "",
    projectPocName: "",
    projectPocEmail: "",
    projectPocNumber: "",
    projectManagerId: "",
    projectFunctionalHeadId: "",
    salesRepresentativeId: "",
    revenueGenerated: 0,
    projectStartDate: null,
    projectEndDate: null,
    totalCost: 0,
    currency: "",
    comments: "",
    accountInvoice: "",
    companyIndustry: "",
    companyAddress: "",
    companyRegion: "",
    file: "",
    fileType: "",
    sharePointWebUrl: "",
    clientRegionList: [],
    // SalesRepresentive Fields Start
    addEditSalesRepresentative: {
      id: "",
      number: "",
      name: "",
      email: "",
      department: "",
    },
    columns: [
      { label: "No", key: "no" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Department", key: "department" },
      { label: "Actions", key: "actions" },
    ],
    salesRepresentativeName: "",
    salesRepresentativeList: [],
    openConfirmCancel: false,
    isSalesRepresentativeOpen: false,
    isAddEditModalOpen: false,
    // Resource Fields Start
    usersList: [],
    resources: [emptyResource],
    updateResources: [],
    rowIndex: -1,
    activeColumn: "",
    removeRowIndex: -1,
    //Project Estimate Fields Start
    totalTimeSpend: 0,
    projectEstimate: [emptyProjectEstimate],
    updatedProjectEstimate: [emptyProjectEstimate],
    savedProjectEstimate: [],
    // Client Fields Start
    invalidClientFields: null,
    projectClients: [emptyClientDetail],
    updatedProjectClients: [emptyClientDetail],
    savedProjectClients: [],
  };

  const emptySalesRepresentative = {
    id: "",
    number: "",
    name: "",
    email: "",
    department: "",
  };
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), initialState);
  const {
    tabValue,
    invalidField,
    invalidClientFields,
    jiraName,
    department,
    companyIndustry,
    companyAddress,
    companyRegion,
    projectName,
    projectType,
    projectStatus,
    projectPocName,
    projectPocEmail,
    projectPocNumber,
    revenueGenerated,
    projectStartDate,
    projectEndDate,
    totalCost,
    currency,
    comments,
    accountInvoice,
    projectManagerId,
    projectFunctionalHeadId,
    salesRepresentativeId,
    salesRepresentativeList,
    columns,
    openConfirmCancel,
    isSalesRepresentativeOpen,
    isAddEditModalOpen,
    addEditSalesRepresentative,
    salesRepresentativeName, // use for salesRepresentative Modal
    clientRegionList,
    usersList,
    resources,
    updateResources,
    projectEstimate,
    updatedProjectEstimate,
    savedProjectEstimate,
    rowIndex,
    activeColumn,
    removeRowIndex,
    file,
    fileType,
    sharePointWebUrl,
    projectClients,
    updatedProjectClients,
    savedProjectClients,
    totalTimeSpend,
  } = localState;

  const resourceArray = id ? updateResources : resources;
  const stateKey = id ? "updateResources" : "resources";
  const projectEstimateArray = id ? updatedProjectEstimate : projectEstimate;
  const projectEstimateKey = id ? "updatedProjectEstimate" : "projectEstimate";
  const clientArray = id ? updatedProjectClients : projectClients;
  const clientKey = id ? "updatedProjectClients" : "projectClients";

  const [getUserList] = useLazyGetUserListQuery();
  const [getPreSalesRegion] = useLazyGetPreSalesRegionQuery();
  const [getSalesRepresentative] = useLazyGetSalesRepresentativeQuery();
  const [saveProject1] = useSaveProject1Mutation();
  const [getProjectById1] = useLazyGetProjectById1Query();
  const [updateProject1] = useUpdateProject1Mutation();
  const [getSalesRepresentativeById] = useLazyGetSalesRepresentativeByIdQuery();
  const [saveSalesRepresentative] = useSaveSalesRepresentativeMutation();
  const [updateSalesRepresentative] = useUpdateSalesRepresentativeMutation();
  const [deleteSalesrepresentative] = useDeleteSalesrepresentativeMutation();
  const [getAllResourse] = useLazyGetAllResourseQuery();
  const [getCountries, { data: countriesList }] = useLazyGetCountriesQuery();
  useEffect(() => {
    getSalesRepresentativeData();
    getCountries();
    getPreSalesRegion()
      .unwrap()
      .then((res) => {
        setLocalState({ clientRegionList: res });
      })
      .catch(handleError);
    getUsersListData();
  }, []);

  const transformClient = (data) => {
    const { id, name, designation, emailId, birthday, department, giftEligibility, status, csmConnect, countryName } = data;
    return {
      id: id,
      clientName: name,
      clientEmailId: emailId,
      department: department,
      clientDesignation: designation,
      clientCountry: countryName,
      clientGift: giftEligibility,
      clientBirthday: birthday,
      status: status === "Active" ? true : false,
      csmConnect: csmConnect,
    };
  };
  const transformProjectEstimate = (data) => {
    const { id, originalTimeEstimate, startDate, totalCost } = data;
    return { id, originalTimeEstimate, startDate, totalCost };
  };
  useEffect(() => {
    if (id) {
      getProjectById1({ id })
        .unwrap()
        .then((res) => {
          const spocDetailsList = get(res, "spocDetailsList", []);
          const projectEstimateList = get(res, "projectEstimateList", []);
          const prevRequirements = {
            jiraName: get(res, "jiraName", ""),
            department: get(res, "department", ""),
            companyIndustry: get(res, "companyIndustry", ""),
            companyAddress: get(res, "companyAddress", ""),
            companyRegion: get(res, "companyRegion", ""),
            projectName: get(res, "name", ""),
            projectType: get(res, "projectType", ""),
            projectStatus: get(res, "projectStatus", ""),
            projectPocName: get(res, "projectPocName", ""),
            projectPocEmail: get(res, "projectPocEmail", ""),
            projectPocNumber: get(res, "projectPocNumber", ""),
            projectManagerId: get(res, "projectManager.id", null),
            projectFunctionalHeadId: get(res, "projectFunctionalHead.id", null),
            salesRepresentativeId: get(res, "salesRepresentative.id", null),
            revenueGenerated: get(res, "revenueGenerated", 0),
            projectStartDate: get(res, "startDate", null),
            projectEndDate: get(res, "endDate", null),
            totalCost: get(res, "totalCost", 0),
            currency: get(res, "currency", ""),
            comments: get(res, "comments", ""),
            accountInvoice: get(res, "accountInvoice", ""),
            sharePointWebUrl: get(res, "sharePointWebUrl", ""),
            updatedProjectEstimate:
              projectEstimateList.length > 0 ? projectEstimateList.map(transformProjectEstimate) : [emptyProjectEstimate],
            savedProjectEstimate:
              projectEstimateList.length > 0 ? projectEstimateList.map(transformProjectEstimate) : [emptyProjectEstimate],
            updatedProjectClients: spocDetailsList.length > 0 ? spocDetailsList.map(transformClient) : [emptyClientDetail],
            savedProjectClients: spocDetailsList.length > 0 ? spocDetailsList.map(transformClient) : [emptyClientDetail],
            totalTimeSpend: get(res, "totalTimeSpend", 0),
          };
          setLocalState(prevRequirements);
        })
        .catch(handleError);

      getAllResourse(id)
        .unwrap()
        .then((res) => {
          let prevReq = [];
          get(res, "results", []).map((resource, index) => {
            prevReq[index] = {
              id: get(resource, "id", ""),
              userId: get(resource, "user.id", ""),
              startDate: get(resource, "startDate", null),
              endDate: get(resource, "endDate", null),
              monthlySpendHour: get(resource, "monthlySpendHour", ""),
              actualHoursSpent: get(resource, "actualHoursSpent", ""),
              resourceType: get(resource, "resourceType", ""),
              noticePeriod: get(resource, "noticePeriod", ""),
              costClient: get(resource, "costClient", ""),
              invoiceDate: get(resource, "invoiceDate", null),
              comment: get(resource, "comment", ""),
            };
          });
          setLocalState({ updateResources: [...prevReq] });
        })
        .catch(handleError);
      if (updateResources.length === 0) {
        setLocalState({ updateResources: [...updateResources, emptyResource] });
      }
    }
  }, [id]);

  const getUsersListData = () => {
    getUserList({ page: 0, size: 10000 })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ usersList: allTableRows });
      })
      .catch(handleError);
  };

  const getSalesRepresentativeData = () => {
    getSalesRepresentative({ page: INITIAL_PAGE, rowsPerPage: FULL_ROWS_PER_PAGE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ salesRepresentativeList: allTableRows });
      })
      .catch(handleError);
  };

  const getSalesRepresentativeByIdData = (id) => {
    getSalesRepresentativeById({ id })
      .unwrap()
      .then((res) => {
        setLocalState({
          addEditSalesRepresentative: {
            ...addEditSalesRepresentative,
            id: get(res, "id", ""),
            number: get(res, "no", null),
            name: get(res, "name", ""),
            email: get(res, "email", ""),
            department: get(res, "department", ""),
          },
        });
      })
      .catch(handleError);
  };

  // ####################### Handle Change Functions #########################
  const handleTabChange = (event, newValue) => {
    // if (newValue > tabValue && !canMoveForward() && !disableCondition()) {
    //   toast.error(T.REQUIRED_FIELDS_EMPTY);
    //   return;
    // }
    setLocalState({ tabValue: newValue });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formatFileForBE = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const base64 = await convertBase64(file);
    return { fileName, base64 };
  };

  const handleFileUpload = async (event, value) => {
    const { name } = event.target;
    const { fileName, base64 } = await formatFileForBE(event);
    setLocalState({ [name]: fileName, file: base64 });
  };

  const handleDeleteFile = (event) => {
    const { name } = event.target;
    setLocalState({ [name]: "", file: "" });
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (type, value) => {
    setLocalState({ [type]: value });
  };

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);
  const onHandleDateChange = (newValue, type) => {
    if (newValue !== null) {
      const validDate = new Date(newValue);
      if (isValid(validDate)) {
        setLocalState({ [type]: getBEDateFormat(validDate) });
      }
    } else {
      setLocalState({ [type]: null });
    }
  };

  const onChangeSalesRepresentative = (e, type, value) => {
    if (e === null) {
      setLocalState({ addEditSalesRepresentative: { ...addEditSalesRepresentative, [type]: value } });
    } else {
      const { name, value } = e.target;
      setLocalState({ addEditSalesRepresentative: { ...addEditSalesRepresentative, [name]: value } });
    }
  };

  // ####################### Resource Details Functions #########################
  const setActiveInput = (i, e) => {
    setLocalState({ rowIndex: i });
  };

  const addNewRow = () => {
    const data = resourceArray;
    data.push(emptyResource);
    setLocalState({ [stateKey]: data, rowIndex: data.length - 1 });
  };

  const removeRow = (i) => {
    setLocalState({ removeRowIndex: i });
  };

  const removeRowAction = () => {
    let i = removeRowIndex;
    const data = resourceArray;
    data.splice(i, 1);
    setLocalState({ [stateKey]: data, removeRowIndex: -1 });
  };

  const onHandleTableChange = (e, index) => {
    const { name, value } = e.target;
    let data = [...resourceArray];
    data[index] = { ...data[index], [name]: value };
    setLocalState({ [stateKey]: data });
  };

  const onHandleTableDateChange = (newValue, type, index) => {
    const validDate = newValue ? new Date(newValue) : null;
    let data = [...resourceArray];
    data[index] = { ...data[index], [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null };
    setLocalState({ [stateKey]: data });
  };

  const onHandleTableAutoComplete = (type, newValue, index) => {
    let data = [...resourceArray];
    data[index] = { ...data[index], [type]: newValue };
    setLocalState({ [stateKey]: data });
  };
  // #################### Project Estimate Functions #################
  const onHandleEstAddMore = () => {
    projectEstimateArray.push(emptyProjectEstimate);
    setLocalState({ [projectEstimateKey]: projectEstimateArray });
  };

  const onHandleEstRemove = () => {
    let i = removeRowIndex;
    projectEstimateArray.splice(i, 1);
    setLocalState({ [projectEstimateKey]: projectEstimateArray, removeRowIndex: -1 });
  };

  const onHandleEstimateChange = (e, index) => {
    const { name, value } = e.target;
    let data = [...projectEstimateArray];
    data[index] = { ...data[index], [name]: value };
    setLocalState({ [projectEstimateKey]: data });
  };
  const onHandleEstimateDateChange = (newValue, type, index) => {
    const validDate = newValue ? new Date(newValue) : null;
    let data = [...projectEstimateArray];
    data[index] = { ...data[index], [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null };
    setLocalState({ [projectEstimateKey]: data });
  };

  // #################### Client Details Functions ###################
  const onHandleReqAddMore = () => {
    clientArray.push(emptyClientDetail);
    setLocalState({ [clientKey]: clientArray });
  };

  const onHandleReqRemove = () => {
    let i = removeRowIndex;
    clientArray.splice(i, 1);
    setLocalState({ [clientKey]: clientArray, removeRowIndex: -1 });
  };

  const handleClientChange = (e, index) => {
    const { name, value } = e.target;
    let data = [...clientArray];
    data[index] = { ...data[index], [name]: value };
    setLocalState({ [clientKey]: data });
  };

  const handleClientAutoCompleteChange = (type, value, index) => {
    let data = [...clientArray];
    data[index] = { ...data[index], [type]: value };
    setLocalState({ [clientKey]: data });
  };

  const handleGiftSwitch = (index, type) => {
    let data = [...clientArray];
    data[index] = { ...data[index], [type]: !data[index][type] };
    setLocalState({ [clientKey]: data });
  };
  const handleClientDateChange = (newValue, type, index) => {
    const validDate = newValue ? new Date(newValue) : null;
    let data = [...clientArray];
    data[index] = { ...data[index], [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null };
    setLocalState({ [clientKey]: data });
  };

  // ####################### Modal Functions #########################
  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };

  const openSalesRepresentativeModal = () => {
    setLocalState({ isSalesRepresentativeOpen: true });
  };

  const handleCloseSalesRepresentative = () => {
    setLocalState({ isSalesRepresentativeOpen: false });
  };

  const openAddEditModal = (id, name) => {
    if (id) {
      setLocalState({ salesRepresentativeName: name });
      getSalesRepresentativeByIdData(id);
    }
    setLocalState({ isAddEditModalOpen: true, isSalesRepresentativeOpen: false });
  };

  const handleCloseAddEditModal = () => {
    setLocalState({
      salesRepresentativeName: "",
      addEditSalesRepresentative: emptySalesRepresentative,
      isAddEditModalOpen: false,
      isSalesRepresentativeOpen: true,
    });
    getSalesRepresentativeData();
  };

  const getProjectDetails = () => ({
    ...localState,
    salesRepresentativeList,
    projectManagerList: get(projectManagers, "results", []),
    vicePresidentList: get(functionalManagers, "results", []),
    departmentsList: get(departmentList, "results", []),
    clientRegionList,
    invalidField,
    projectDetailsFields,
    sharePointWebUrl,
    handleFileUpload,
    handleDeleteFile,
    onHandleChange,
    onHandleAutoCompleteChange,
    onHandleDateChange,
    openSalesRepresentativeModal,
  });

  const getResourceDetails = () => ({
    usersList,
    rowIndex,
    activeColumn,
    getAllResourseList: id ? updateResources : resources,
    addNewRow,
    removeRow,
    setActiveInput,
    onHandleTableChange,
    onHandleTableDateChange,
    onHandleTableAutoComplete,
  });

  const getProjectEstimate = () => ({
    id,
    totalTimeSpend,
    projectEstimate: projectEstimateArray,
    savedProjectEstimate,
    onHandleEstAddMore,
    removeRow,
    onHandleEstimateChange,
    onHandleEstimateDateChange,
  });

  const getClientDetails = () => ({
    id,
    projectClients: clientArray,
    savedProjectClients,
    invalidClientFields,
    clientDetailFields,
    departmentsList: get(departmentList, "results", []),
    countriesList,
    onHandleReqAddMore,
    removeRow,
    handleGiftSwitch,
    handleClientChange,
    handleClientAutoCompleteChange,
    handleClientDateChange,
  });

  // Below are the validation checks for Project Details
  const projectDetailsFields = [
    { name: "projectName", value: !projectName || !isString(projectName) },
    { name: "projectType", value: !projectType },
    { name: "projectStatus", value: !projectStatus },
    { name: "department", value: !department },
    { name: "projectPocName", value: !projectPocName },
    { name: "salesRepresentativeId", value: !salesRepresentativeId },
    { name: "projectManagerId", value: !projectManagerId },
    { name: "projectFunctionalHeadId", value: !projectFunctionalHeadId },
    { name: "currency", value: !currency },
    { name: "projectStartDate", value: !projectStartDate },
    { name: "projectEndDate", value: !projectEndDate },
    { name: "companyRegion", value: !companyRegion },
  ];

  // Below are the validation checks for Client Details
  const clientDetailFields = [
    { name: "clientName", value: (index) => !clientArray[index]["clientName"] || !isString(clientArray[index]["clientName"]) },
    {
      name: "clientEmailId",
      value: (index) => clientArray[index]["clientEmailId"] && !isEmail(clientArray[index]["clientEmailId"]),
    },
  ];
  const validation = () => {
    const invalidFields = projectDetailsFields.filter((field) => field.value).map((field) => field.name);
    const invalidClientFields = clientArray.map((client, index) =>
      clientDetailFields.filter((field) => field.value(index)).map((field) => field.name),
    );
    const someEmptyClients = clientArray.some((item) => get(item, "clientName", "").trim() === "");

    if (invalidFields.length > 0) {
      toast.error(`Invalid Field.`);
      setLocalState({ invalidField: invalidFields, tabValue: "1" });
      return false;
    }

    if (someEmptyClients || invalidClientFields.some((item) => item.length > 0)) {
      toast.error(`Invalid Client Details`);
      setLocalState({ invalidClientFields: invalidClientFields, tabValue: "4" });
      return false;
    }

    setLocalState({ invalidField: null, invalidClientFields: null });
    return true;
  };
  const mappedClientArray = clientArray.map((item) => ({
    id: item.id,
    name: item.clientName,
    emailId: item.clientEmailId,
    department: item.department,
    designation: item.clientDesignation,
    birthday: item.clientBirthday,
    countryName: item.clientCountry,
    giftEligibility: item.clientGift,
    csmConnect: item.csmConnect,
    status: item.status ? "Active" : "Inactive",
  }));

  const mappedProjectEstimate = projectEstimateArray.map((item) => ({
    id: item.id,
    startDate: item.startDate,
    originalTimeEstimate: parseInt(item.originalTimeEstimate),
    totalCost: parseInt(item.totalCost),
  }));

  const payload = {
    accountInvoice,
    companyAddress,
    companyIndustry,
    companyRegion,
    comments,
    currency,
    department,
    endDate: projectEndDate,
    jiraName,
    name: projectName,
    projectFunctionalHeadId: parseInt(projectFunctionalHeadId),
    projectManagerId: parseInt(projectManagerId),
    projectPocEmail,
    projectPocName,
    projectPocNumber,
    projectStatus,
    projectType,
    revenueGenerated,
    salesRepresentativeId: parseInt(salesRepresentativeId),
    startDate: projectStartDate,
    totalCost: parseInt(totalCost),
    file,
    fileType,
    resourceDtoList: resourceArray.filter((item) => item.userId !== ""),
    projectEstimateList: mappedProjectEstimate,
    spocDetailsList: mappedClientArray,
  };

  // ####################### Submit/Update Functions #########################
  const handleSubmit = () => {
    if (!validation()) return;
    saveProject1({ requestDto: payload })
      .unwrap()
      .then((res) => {
        toast.success(T.PROJECT_ADDED_SUCCESSFULLY);
        handleClose();
      })
      .catch(handleError);
  };

  const handleUpdate = () => {
    if (!validation()) return;
    updateProject1({ requestDto: { id: id, ...payload } })
      .unwrap()
      .then((res) => {
        toast.success(T.PROJECT_UPDATED_SUCCESSFULLY);
        handleClose();
      })
      .catch(handleError);
  };

  const handleSubmitSalesRepresentative = (id) => {
    const payload = {
      department: get(addEditSalesRepresentative, "department", ""),
      name: get(addEditSalesRepresentative, "name", ""),
      email: get(addEditSalesRepresentative, "email", ""),
    };
    if (id) {
      updateSalesRepresentative({ requestDto: { id: id, ...payload } })
        .unwrap()
        .then((res) => {
          toast.success(T.SALES_REPRESENTATIVE_UPDATED_SUCCESSFULLY);
          handleCloseAddEditModal();
        })
        .catch(handleError);
    } else {
      saveSalesRepresentative({ requestDto: payload })
        .unwrap()
        .then((res) => {
          toast.success(T.SALES_REPRESENTATIVE_SAVED_SUCCESSFULLY);
          handleCloseAddEditModal();
        })
        .catch(handleError);
    }
  };

  const handleDeleteSalesRepresentative = (id) => {
    deleteSalesrepresentative({ id })
      .unwrap()
      .then((res) => {
        toast.success(T.SALES_REPRESENTATIVE_DELETED_SUCCESSFULLY);
        handleCloseSalesRepresentative();
        getSalesRepresentativeData();
      })
      .catch(handleError);
  };

  return (
    <>
      <TabContext value={tabValue}>
        <TabList
          variant="scrollable"
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          sx={{
            "& .MuiTab-root.Mui-selected": { color: BACKGROUND.black },
            "& .MuiTabs-indicator": { left: 0, backgroundColor: NETSMARTZ_THEME_COLOR },
            "& .MuiTab-root": { fontWeight: 600 },
          }}
        >
          <Tab label={T.PROJECT_DETAILS} value="1" />
          <Tab label={T.RESOURSE_DETAILS} value="2" />
          {projectType !== T.BYT && <Tab label={T.PROJECT_ESTIMATE} value="3" />}
          <Tab label={T.SPOC_DETAILS} value="4" />
        </TabList>
        <Box
          sx={{
            height: "calc(100vh - 258px)",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <TabPanel value="1">
            <ProjectDetail {...getProjectDetails()} />
          </TabPanel>
          <TabPanel value="2">
            <ResourseDetail {...getResourceDetails()} />
          </TabPanel>
          <TabPanel value="3">
            <ProjectEstimate {...getProjectEstimate()} />
          </TabPanel>
          <TabPanel value="4">
            <ClientDetail {...getClientDetails()} />
          </TabPanel>
        </Box>
        <MISFooterButton
          proceedButtonText={id ? T.UPDATE : T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={confrmCancelModal}
          handleSubmit={id ? handleUpdate : handleSubmit}
        />
        <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
        <SalesRepresentativeDialog
          columns={columns}
          isSalesRepresentativeOpen={isSalesRepresentativeOpen}
          salesRepresentativeList={salesRepresentativeList}
          isAddEditModalOpen={isAddEditModalOpen}
          salesRepresentativeName={salesRepresentativeName}
          addEditSalesRepresentative={addEditSalesRepresentative}
          departmentsList={get(departmentList, "results", [])}
          handleCloseSalesRepresentative={handleCloseSalesRepresentative}
          openAddEditModal={openAddEditModal}
          handleDeleteSalesRepresentative={handleDeleteSalesRepresentative}
          onChangeSalesRepresentative={onChangeSalesRepresentative}
          handleSubmitSalesRepresentative={handleSubmitSalesRepresentative}
          handleCloseAddEditModal={handleCloseAddEditModal}
        />
      </TabContext>
      <ConfirmSubmit
        proceedButtonText={T.DELETE}
        cancelButtonText={T.CANCEL}
        dialogMessageText={T.CONFIRM_DELETE}
        openConfirmSubmit={removeRowIndex !== -1}
        handleSubmit={tabValue === "2" ? removeRowAction : tabValue === "3" ? onHandleEstRemove : onHandleReqRemove}
        handleClose={() => setLocalState({ removeRowIndex: -1 })}
      />
    </>
  );
};

export default Form;
