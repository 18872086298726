import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setActiveVendorById: builder.query({
      query: ({ id, status }) => ({
        url: `/vendor/set/vendor/status?vendorId=${id}&status=${status}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useLazySetActiveVendorByIdQuery } = extendedApi;
