import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSalesRepresentative: builder.query({
      query: ({ page, rowsPerPage }) => ({
        url: `/salesRepresentative/get/all?page=${page}&size=${rowsPerPage}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetSalesRepresentativeQuery } = extendedApi;
