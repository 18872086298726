import { Tab, Icon, styled, useMediaQuery, Box, useTheme, Badge } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";

const NavIcon = ({ icon, isActive, isTablet }) => (
  <Icon
    sx={{
      display: "flex",
      height: "inherit",
      width: "inherit",
      mr: 1,
      mb: 0.6,
      filter: isActive
        ? "invert(50%) sepia(94%) saturate(887%) hue-rotate(348deg) brightness(101%) contrast(92%)"
        : BACKGROUND.black,
    }}
  >
    <Box
      component="img"
      src={icon}
      margin="auto"
      sx={{ objectFit: "contain" }}
      pl={1}
      width={!isTablet ? 20 : 30}
      height={!isTablet ? 20 : 30}
    />
  </Icon>
);

const StyledTab = styled(Tab)(({ theme, isactive }) => ({
  textTransform: "capitalize",
  display: "block",
  color: isactive === "true" ? theme.palette.themeColor : theme.palette.background.black,
  fontSize: "14px",
  padding: 1.5,
  minWidth: "unset",
  minHeight: "unset",
  opacity: 1,
  margin: "0 0.5vw",
}));

const StyledBadge = styled(Badge)(({ theme, isActive }) => ({
  "& .MuiBadge-badge": {
    fontSize: 11,
    height: 15,
    minWidth: 15,
    backgroundColor: isActive === "true" ? NETSMARTZ_THEME_COLOR : BACKGROUND.black,
    color: "#FFFFFF",
    padding: "0px",
    top: "22%",
    right: "20%",
  },
  // "& .css-18qwn95-MuiBadge-badge": {

  // },
}));

const NavItem = ({ label, icon, to, isActive = false, index, badge = false, badgeContent }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", 1110));

  return (
    <StyledBadge
      badgeContent={badgeContent}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      overlap="circular"
      isActive={isActive.toString()}
      invisible={!badge}
    >
      <StyledTab
        isactive={isActive.toString()}
        iconPosition={!isTablet ? "start" : "top"}
        icon={<NavIcon icon={icon} isActive={isActive} isTablet={isTablet} />}
        component={Link}
        label={!isTablet && label}
        to={to}
        value={index}
      />
    </StyledBadge>
  );
};

NavItem.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  index: PropTypes.number,
};

export default NavItem;
