import { Children } from "react";
import { useLocation } from "react-router-dom";
import { Box, Tabs } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderGPTW from "assets/Header_GPTW.png";
// import ProfileLogo from "assets/ProfileLogo.png";
import matchPathWithParams from "utils/matchPathWithParams";
import { navbarItems, dropDownItems, profileDropDownItems, mobileDropDownItems } from "./menuItems";
import MenuButton from "./MenuButton";
import NavItem from "./NavItem";
import { AccountCircle } from "@mui/icons-material";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
const NavigationMenu = () => {
  const location = useLocation();
  const { pathname } = location;

  const [navbarItem, dropItems, profileItems, mobileItems] = [
    navbarItems(),
    dropDownItems(),
    profileDropDownItems(),
    mobileDropDownItems(),
  ];
  const activeIndex = navbarItem.findIndex(
    ({ childList }) => childList && childList.some((childPath) => matchPathWithParams(pathname, childPath)),
  );
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" height={65}>
      <Box component="img" alt="netsmartz_logo" src={HeaderGPTW} pl={1} width={180} />
      <Box justifyContent="space-evenly" sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}>
        <Tabs
          scrollButtons={true}
          variant={"scrollable"}
          // slots={
          //   (EndScrollButtonIcon ? <ChevronRightIcon /> : <ChevronRightIcon />,
          //   StartScrollButtonIcon ? <ChevronLeftIcon /> : <ChevronLeftIcon />)
          // }
          // ScrollButtonComponent={(props) => {
          //   if (["left", "right"].includes(props.direction) && !props.disabled) {
          //     return (
          //       <IconButton {...props}>
          //         <ChevronLeftIcon />
          //       </IconButton>
          //     );
          //   } else if (["left", "right"].includes(props.direction) && !props.disabled) {
          //     return (
          //       <IconButton {...props}>
          //         <ChevronRightIcon />
          //       </IconButton>
          //     );
          //   } else {
          //     return null;
          //   }
          // }}
          visibleScrollbar={"auto"}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "background.white",
            },
            "& .MuiTabs-scrollableX": {
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                height: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: NETSMARTZ_THEME_COLOR,
                borderRadius: "3px",
              },
            },
            alignItems: "center",
            justifyContent: "space-between",
          }}
          value={activeIndex}
        >
          {Children.toArray(
            navbarItem.map((navItem, index) => {
              const { title, route, icon, permission, badge, badgeContent } = navItem;
              if (!permission) {
                return null;
              }

              return (
                <NavItem
                  isActive={index === activeIndex}
                  icon={icon}
                  label={title}
                  to={route}
                  index={index}
                  badge={badge}
                  badgeContent={badgeContent}
                />
              );
            }),
          )}
        </Tabs>
      </Box>

      <Box justifyContent="end" sx={{ flexGrow: 1, display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}>
        <MenuButton iconType={<MenuIcon />} items={mobileItems} />
      </Box>

      <Box display="flex" sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}>
        <MenuButton iconType={<MoreHorizIcon />} items={dropItems} />
        <MenuButton iconType={<AccountCircle />} items={profileItems} isHandleLogout />
      </Box>
    </Box>
  );
};

export default NavigationMenu;
