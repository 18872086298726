import PropTypes from "prop-types";
import noop from "lodash/noop";
import { BACKGROUND } from "theme/colors";
import { Typography } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import T from "T";
import MISFooterButton from "components/common/MISFooterButton";

const ConfirmSubmit = ({
  proceedButtonText = "",
  cancelButtonText = "",
  dialogMessageText = "",
  openConfirmSubmit = false,
  handleClose = noop,
  handleSubmit = noop,
  disableSubmit = false,
  showLoader = false,
}) => (
  <MISDialog open={openConfirmSubmit} handleClose={handleClose}>
    <Typography variant="body1" textAlign="center" p="20px 60px" sx={{ color: BACKGROUND.black, fontWeight: 500 }}>
      {dialogMessageText || T.ARE_YOU_SURE}
    </Typography>

    <MISFooterButton
      proceedButtonText={proceedButtonText || T.PROCEED}
      cancelButtonText={cancelButtonText || T.CANCEL}
      justify="center"
      size="medium"
      sx={{ mb: 2, ml: 2, mr: 2 }}
      handleClose={() => {
        handleClose();
      }}
      handleSubmit={handleSubmit}
      disableProceed={disableSubmit}
      showLoader={showLoader}
    />
  </MISDialog>
);

ConfirmSubmit.propTypes = {
  proceedButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  dialogMessageText: PropTypes.string,
  openConfirmSubmit: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  showLoader: PropTypes.func,
};

export default ConfirmSubmit;
