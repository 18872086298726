import { Box, Button, Card, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import T from "T";
import { NETSMARTZ_THEME_COLOR, TEXT } from "theme/colors";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GET_SIZE } from "utils/responsive";
import { handleError } from "utils/error";
import { useLazyGetQuestionQuery } from "api/MockInterview/getQuestion";
import { useSubmitAudioMutation } from "api/MockInterview/submitAudio";
import { shallowEqual, useDispatch } from "react-redux";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import MicIcon from "@mui/icons-material/Mic";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import StopCircleIcon from "@mui/icons-material/StopCircle";

import { setStartTime, updateTimeLeft } from "slices/mockInterviewSlice";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { usePostUserInfoMutation } from "api/MockInterview/postMockInterviewUserInfo";
import { MISCurrentUser } from "utils/validations";
// import QuestionsBoxButtons from "./QuestionsBoxButtons";
import MISLoader from "components/common/MISLoader";
// import InstructionsModal from "./InstructionsModal";
// import { useLazyGetExistingResponsesQuery } from "api/MockInterview/getExistingResponses";
import { ReactMediaRecorder, useReactMediaRecorder } from "react-media-recorder";

import {
  manageVideoInterviewUserLogin,
  videoInterviewFormDataStore,
  videoInterviewInstructionModal,
} from "slices/videoInterviewSlice";
import { useSubmitVideoResponseMutation } from "api/VideoInterview/postInterviewResponses";
import VideoInterviewHeader from "../VideoInterviewHeader";
const APP_PREFIX = "/mock-interview";
export const convertBase64ToBlob = (base64String) => {
  const binaryData = atob(base64String);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob object from the binary data
  const blob = new Blob([uint8Array], { type: "application/octet-stream" });
  return blob;
};

// Helper function to convert FormData to a simple object
export const convertFormDataToObject = (formData) => {
  const obj = {};
  formData.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

// Helper function to convert object back to FormData
const convertObjectToFormData = (obj) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};
const InterviewQuestions = () => {
  const videoRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const getvideo = () => {
    navigator.mediaDevices.getUserMedia({ video: { width: "inherit", height: "inherit" } }).then((stream) => {
      let video = videoRef?.current;
      if (video) {
        video.srcObject = stream;
        video.play().catch((error) => {
          console.error(error);
        });
      }
    });
  };

  const dispatch = useDispatch();
  // const location = useLocation();

  // const { user } = MISCurrentUser();
  // const userId = get(user, "user.id", "");
  // const userName = get(user, "user.userName", "");
  // const empCode = get(user, "user.employeeCode", "");
  let videoResponsesInSession = sessionStorage.getItem("videoResponsesCount");
  useEffect(() => {
    if (!videoResponsesInSession || videoResponsesInSession === 0 || videoResponsesInSession === undefined) {
      dispatch(videoInterviewFormDataStore({ videoResponsesFormData: {} }));
    }
  }, [videoResponsesInSession]);
  const {
    videoResponses,
    videoResponsesFormData,
    participantDetails,
    videoInterviewUserRegistered,
    responseArr,
    startTime,
    timeLeft,
    openInstructionModal,
  } = useSelector(
    (state) => ({
      videoResponses: get(state, "VideoInterviewSlice.videoResponses", []),
      videoResponsesFormData: get(state, "VideoInterviewSlice.videoResponsesFormData", {}),
      participantDetails: get(state, "VideoInterviewSlice.participantDetails", {}),
      videoInterviewUserRegistered: get(state, "VideoInterviewSlice.videoInterviewUserRegistered", false),
      responseArr: get(state, "VideoInterviewSlice.responseArr", []),
      startTime: get(state, "VideoInterviewSlice.startTime", null),
      openInstructionModal: get(state, "VideoInterviewSlice.openInstructionModal", null),
      timeLeft: get(state, "VideoInterviewSlice.timeLeft", null),
    }),
    shallowEqual,
  );
  const storedVideoResponsesCount = Object.keys(videoResponsesFormData).length;
  const { isXs, isMd } = GET_SIZE();
  const [getQuestion, { data: question, isFetching }] = useLazyGetQuestionQuery();
  // const [submitResponses, { isFetching: submitResponseFetching }] = useSubmitResponsesMutation();
  const [submitVideoResponse, { isFetching: submitResponseFetching }] = useSubmitVideoResponseMutation();
  // const [getExistingResponses] = useLazyGetExistingResponsesQuery();
  const [postUserInfo] = usePostUserInfoMutation();
  const [submitAudio, audioData] = useSubmitAudioMutation();
  const { isLoading: submitIsLoading } = audioData;
  const navigate = useNavigate();
  const mimeType = "audio/webm";
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [videoFormData, setVideoFormData] = useState(new FormData());
  const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ video: true, audio: true });

  // const [recordedVideoList,setRecordedVideoList] = useState([])
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    questionsList: [],
    answersList: [],
    questionStep: 0,
    recordedVideoList: [],
    isLoading: false,
    // isVideoInterviewAssigned: location?.state?.isVideoInterviewAssigned ? location?.state?.isVideoInterviewAssigned : null,
    startTimer: false,
    videoResponsesSubmitted: false,
  });
  const {
    questionsList,
    isLoading,
    questionStep,
    recordedVideoList,
    isVideoInterviewAssigned,
    startTimer,
    answersList,
    videoResponsesSubmitted,
  } = localState;

  // Function to convert mediaBlobUrl to Blob
  const convertUrlToBlob = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
    // setMediaBlob(blob); // Set Blob to state
  };
  // useEffect(() => {
  //   setLocalState({ isLoading: true });
  //   if (responseArr.length === 0) {
  //     getExistingResponses({ candidateName: "Nishant" })
  //       .unwrap()
  //       .then((res) => {
  //         // const isVideoInterviewAssigned = get(res, "isVideoInterviewAssigned", null);
  //         setLocalState({ isVideoInterviewAssigned: true });
  //         const responseArr = get(res, "mockInterviewLevels", []);
  //         const createdAt = get(res, "createdAt", []);
  //         const resLength = responseArr.length;
  //         if (resLength > 0) {
  //           navigate(`/mock-interview-responses`, {
  //             state: { responseArr: responseArr, createdAt: createdAt },
  //           });
  //         }
  //       })
  //       .catch(handleError)
  //       .finally(() => {
  //         setLocalState({ isLoading: false });
  //       });
  //   } else {
  //     setLocalState({ isLoading: false });
  //   }
  // }, [responseArr.length]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }
  }, [timeLeft]);

  // open the info dialog
  useEffect(() => {
    if (isVideoInterviewAssigned && timeLeft === 20 * 60) {
      dispatch(videoInterviewInstructionModal({ openInstructionModal: true }));
    } else {
      dispatch(videoInterviewInstructionModal({ openInstructionModal: false }));
      setLocalState({ openInitialDialog: true });
    }
  }, [isVideoInterviewAssigned]);

  useEffect(() => {
    if ((!openInstructionModal || openInstructionModal !== null) && !startTime && startTimer) {
      const now = new Date().getTime();
      dispatch(setStartTime({ startTime: now }));
    }
  }, [openInstructionModal, startTime, dispatch, startTimer]);
  useEffect(() => {
    if (!openInstructionModal && startTime) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsedTime = Math.floor((now - startTime) / 1000);
        const remainingTime = Math.max(0, 20 * 60 - elapsedTime);
        dispatch(updateTimeLeft({ timeLeft: remainingTime }));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [openInstructionModal, startTime, dispatch]);

  // useEffect(() => {
  //   dispatch(videoInterviewFormDataStore({ videoResponsesFormData: {} }));
  // }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  const [searchParams] = useSearchParams();
  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  const quesStep = +searchParams.get("ques");

  useEffect(() => {
    if (quesStep === 0 && quesStep !== null && !videoInterviewUserRegistered) {
      const userInfoPayload = {
        user_name: get(participantDetails, "name", ""),
        employee_code: `UID${get(participantDetails, "id", "")}`,
      };
      postUserInfo(userInfoPayload)
        .unwrap()
        .then(() => {
          dispatch(manageVideoInterviewUserLogin({ videoInterviewUserRegistered: true }));
          getQuestion(quesStep)
            .unwrap()
            .then((res) => {
              const questions = [...questionsList];
              questions.push(res?.question);
              setLocalState({ questionsList: questions });
            })
            .catch(handleError);
        })
        .catch(handleError);
    }
  }, []);

  useEffect(() => {
    if (quesStep === 0 || videoResponsesSubmitted) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    }
    if (quesStep > 0 || videoInterviewUserRegistered) {
      if (quesStep === null) {
        return;
      }
      getQuestion(quesStep)
        .unwrap()
        .then((res) => {
          const questions = [...questionsList];
          questions.push(res?.question);
          setLocalState({ questionsList: questions });
        })
        .catch(handleError);
    }
  }, [quesStep]);

  const getCameraMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        handleError(err);
        alert("Camera and microphone access permission required !");
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getCameraMicrophonePermission();
    }
  }, [isLoading]);
  useEffect(() => {
    getvideo();
  }, [videoRef, quesStep]);

  const handleNext = () => {
    setLocalState({ questionStep: quesStep + 1 });
    navigate(`/app/screen-test?ques=${quesStep + 1}`);
  };

  const handleSubmit = () => {
    setLocalState({ videoResponsesSubmitted: true });
    // let finalVideoFormData = new FormData();
    // videoResponses.map((data, index) => finalVideoFormData.append(`audio_${index}`, convertBase64ToBlob(data), "recording.wav"));
    // submitAudio(finalVideoFormData)
    //   .unwrap()
    //   .then((res) => {
    //     let audioFiles = [];
    //     videoResponses.map((data, index) =>
    //       audioFiles.push({
    //         file: data,
    //         fileName: `audio${index}.wav`,
    //       }),
    //     );
    //     const saveDataPayload = {
    //       mockInterviewLevelDto: res,
    //       audioFiles: audioFiles,
    //     };
    //     const finalPayload = {
    //       candidateName: "Nishant",
    //       saveDataPayload,
    //     };
    //   })
    //   .catch(handleError);
  };

  const handleInstructionModal = () => {
    dispatch(videoInterviewInstructionModal({ openInstructionModal: false }));
    setLocalState({ startTimer: true });
  };

  return submitIsLoading || submitResponseFetching || isLoading ? (
    <MISLoader />
  ) : (
    <>
      <VideoInterviewHeader />
      <Grid container>
        {
          <>
            <Grid item display={"flex"} justifyContent={"flex-end"} width={"100%"} sx={{ zIndex: 99 }}></Grid>
            <Grid item xs={isMd ? 2 : 3.25} />
            <Grid
              item
              xs={isMd ? 8 : 5.5}
              className="questionDiv"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minHeight: "270px",
                width: "inherit",
                minWidth: "300px",
                maxHeight: "650px",
                backgroundColor: "background.white",
                borderRadius: "8px",
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: "3px 3px 30px -10px rgba(0,0,0,0.3)",
                // overflowY: "auto",
                marginTop: "15px",
              }}
            >
              {videoResponsesSubmitted ? (
                <Box sx={{ display: "block", justifyContent: "center", alignItems: "center", margin: "auto" }}>
                  <Typography variant="h6" align="center" color={NETSMARTZ_THEME_COLOR}>
                    Thank You.
                  </Typography>
                  <Typography variant="h6" align="center">
                    Your responses are submitted successfully.
                  </Typography>
                </Box>
              ) : (
                <>
                  {quesStep < 5 ? (
                    <Stack>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle-2"
                          sx={{
                            flexWrap: "wrap",
                            textAlign: "start",
                            color: TEXT.grayBlue,
                            // marginBottom: 1,
                          }}
                        >
                          {`Question ${quesStep + 1}/5`}
                        </Typography>
                        {/* <Tooltip title={T.INSTRUCTIONS} placement="top">
                          <IconButton aria-label="info" onClick={handleInstructionModal}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip> */}
                      </Box>

                      <Typography
                        variant="h6"
                        sx={{
                          flexWrap: "wrap",
                          textAlign: "start",
                          // fontSize: isXs ? "1.3rem" : "1.6rem",
                          fontSize: "1.125rem",
                          // lineHeight: isXs ? "1.3" : "1.6",
                          fontWeight: "bold",
                          mb: 1,
                        }}
                      >
                        {!isFetching ? question?.question : <Skeleton variant="rectangular" width={"inherit"} height={30} />}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography
                      variant="h2"
                      sx={{
                        flexWrap: "wrap",
                        textAlign: "center",
                      }}
                    >
                      Thank You!
                    </Typography>
                  )}

                  <Grid
                    sx={{ marginBottom: 1 }}
                    // style={{
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                    // }}
                  >
                    <ReactMediaRecorder
                      video
                      render={({ status, startRecording, stopRecording, mediaBlobUrl }) => {
                        useEffect(() => {
                          if (mediaBlobUrl) {
                            const fetchBlob = async () => {
                              const blob = await convertUrlToBlob(mediaBlobUrl); // Await the blob
                              return blob; // Set Blob to state
                            };
                            // const blobStr= fetchBlob()
                            fetchBlob()
                              .then(async (blobStr) => {
                                setRecordedChunks([blobStr]);
                                const blobBase64 = await convertBase64(blobStr);
                                const videoUrl = URL.createObjectURL(blobStr);
                                let storedVideoResponsesFormData = convertObjectToFormData(videoResponsesFormData);
                                // videoFormData.append(`video${questionStep}`, blobStr, `video-recording-${questionStep}.mp4`);
                                storedVideoResponsesFormData.append(
                                  `video-${quesStep}`,
                                  blobStr,
                                  `video-recording-${quesStep}.mp4`,
                                );

                                let newFormDataResponse = new FormData();
                                newFormDataResponse.append("file", blobStr, `video-recording-${quesStep}.mp4`);
                                newFormDataResponse.append("participantId", get(participantDetails, "id", null));

                                // newFormDataResponse.append("question", `question-${quesStep + 1}`);
                                newFormDataResponse.append("question", get(question, "question", `question-${quesStep + 1}`));
                                let answersListCopy = [...videoResponses];
                                answersListCopy.push(btoa(blobBase64));

                                setVideoFormData(videoFormData);
                                setLocalState({ answersList: videoUrl });
                                dispatch(
                                  videoInterviewFormDataStore({
                                    // videoResponses: answersListCopy,
                                    videoResponsesFormData: convertFormDataToObject(storedVideoResponsesFormData),
                                  }),
                                );
                                sessionStorage.setItem("videoResponsesCount", `${quesStep + 1}`);
                                // const finalPayload = newFormDataResponse;
                                submitVideoResponse(newFormDataResponse)
                                  .unwrap()
                                  .then(() => console.log("response Submitted"))
                                  .catch(handleError);
                                // dispatch(mockInterviewStore({ videoResponses: [] }));
                                setAudioChunks([]);
                              })
                              .catch((error) => {
                                console.error("Error fetching Blob:", error);
                              });
                          }
                        }, [mediaBlobUrl]);
                        if (quesStep + 1 <= storedVideoResponsesCount) {
                          return (
                            <>
                              {storedVideoResponsesCount === 5 ? (
                                <Box
                                  sx={{
                                    display: "block",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Typography color={NETSMARTZ_THEME_COLOR} align="center">
                                    Thank you, All your answers has been recorded.
                                  </Typography>
                                  <Typography align="center">Please click on submit button to finish.</Typography>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: "block",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Typography color={NETSMARTZ_THEME_COLOR} align="center">
                                    Thank you, your answer has been recorded.
                                  </Typography>
                                  <Typography align="center">Please click on next to continue.</Typography>
                                </Box>
                              )}

                              <Box
                                sx={{
                                  display: isXs ? "block" : "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {permission && recordingStatus === "inactive" ? (
                                  <Button
                                    variant="contained"
                                    disabled={storedVideoResponsesCount > +quesStep}
                                    sx={{
                                      fontSize: 16,
                                      px: 3,
                                      width: isXs ? "100%" : 125,
                                      minWidth: "90px",
                                      py: 1.3,
                                      mb: 2,
                                      backgroundColor: "themeColor",
                                      borderRadius: 2.1,
                                      "&:hover": {
                                        backgroundColor: "themeColor", // Change to your theme color
                                      },
                                    }}
                                    startIcon={
                                      <MicIcon
                                        sx={{
                                          fontSize: "16px !important",
                                          borderRadius: 50,
                                          p: 0.2,
                                          backgroundColor: "white",
                                          color: NETSMARTZ_THEME_COLOR,
                                        }}
                                      />
                                    }
                                    onClick={() => {
                                      startRecording();
                                      setRecordingStatus("recording");
                                    }}
                                  >
                                    Answer
                                  </Button>
                                ) : null}
                                {recordingStatus === "recording" ? (
                                  <Button
                                    variant="contained"
                                    startIcon={
                                      <StopCircleIcon
                                        sx={{
                                          fontSize: "16px !important",
                                          borderRadius: 50,
                                          p: 0.2,
                                          backgroundColor: "white",
                                          color: NETSMARTZ_THEME_COLOR,
                                        }}
                                      />
                                    }
                                    sx={{
                                      fontSize: 16,
                                      px: 3,
                                      width: isXs ? "100%" : 125,
                                      minWidth: "90px",
                                      maxHeight: "48px",
                                      py: 1.3,
                                      mb: 2,
                                      backgroundColor: "themeColor",
                                      borderRadius: 2.1,
                                      "&:hover": {
                                        backgroundColor: "themeColor", // Change to your theme color
                                      },
                                    }}
                                    disabled={storedVideoResponsesCount < questionStep}
                                    onClick={() => {
                                      stopRecording();
                                      setRecordingStatus("inactive");
                                      setLocalState({
                                        recordedVideoList: [...recordedVideoList, mediaBlobUrl],
                                      });
                                    }}
                                  >
                                    Stop
                                  </Button>
                                ) : null}
                                {quesStep < 4 ? (
                                  <Button
                                    variant="outlined"
                                    onClick={handleNext}
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={storedVideoResponsesCount === quesStep}
                                    sx={{
                                      borderColor: "themeColor",
                                      color: "black",
                                      fontWeight: "bold",
                                      borderRadius: 2,
                                      width: isXs ? "100%" : 100,
                                      fontSize: "15px",
                                      px: 1.4,
                                      py: 1.3,
                                      mb: 2,
                                      "&:hover": {
                                        borderColor: "themeColor", // Change to your theme color
                                      },
                                    }}
                                  >
                                    {T.NEXT}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={handleSubmit}
                                    disabled={
                                      storedVideoResponsesCount < 5 || audioData?.status === "pending"
                                      // ||formSubmitted
                                    }
                                    sx={{
                                      borderColor: "themeColor",
                                      color: "black",
                                      fontWeight: "bold",
                                      borderRadius: 2,
                                      width: isXs ? "100%" : 100,
                                      fontSize: "15px",
                                      px: 1.4,
                                      py: 1.3,
                                      mb: 2,
                                      "&:hover": {
                                        backgroundColor: "themeColor", // Change to your theme color
                                        borderColor: NETSMARTZ_THEME_COLOR,
                                        color: "white",
                                      },
                                    }}
                                  >
                                    {T.SUBMIT}
                                  </Button>
                                )}
                              </Box>
                            </>
                          );
                        } else {
                          return (
                            <Grid>
                              <Box
                                marginBottom={2}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <video
                                  ref={videoRef}
                                  style={{
                                    width: "100%",
                                    height: "inherit",
                                    maxHeight: "300px",
                                    borderRadius: "10px",
                                    transform: "scaleX(-1)",
                                    objectFit: "fill",
                                  }} // This flips the video horizontally
                                  //  width={"100%"}
                                  //  height={"300px"}
                                  //  autoPlay
                                  playsInline
                                />
                              </Box>
                              {/* <p>{status}</p> */}
                              <Box
                                sx={{
                                  display: isXs ? "block" : "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {permission && recordingStatus === "inactive" ? (
                                  <Button
                                    variant="contained"
                                    disabled={storedVideoResponsesCount > +quesStep}
                                    sx={{
                                      fontSize: 16,
                                      display: "flex",
                                      px: 3,
                                      width: isXs ? "100%" : 125,
                                      minWidth: "90px",
                                      py: 1.3,
                                      // mb: 2,
                                      height: "48px",
                                      backgroundColor: "themeColor",
                                      borderRadius: 2.1,
                                      "&:hover": {
                                        backgroundColor: "themeColor", // Change to your theme color
                                      },
                                    }}
                                    startIcon={
                                      <MicIcon
                                        sx={{
                                          fontSize: "16px !important",
                                          borderRadius: 50,
                                          p: 0.2,
                                          backgroundColor: "white",
                                          color: NETSMARTZ_THEME_COLOR,
                                        }}
                                      />
                                    }
                                    onClick={() => {
                                      startRecording();
                                      setRecordingStatus("recording");
                                    }}
                                  >
                                    Answer
                                  </Button>
                                ) : null}
                                {recordingStatus === "recording" ? (
                                  <Button
                                    variant="contained"
                                    startIcon={
                                      <StopCircleIcon
                                        sx={{
                                          fontSize: "16px !important",
                                          borderRadius: 50,
                                          p: 0.2,
                                          backgroundColor: "white",
                                          color: NETSMARTZ_THEME_COLOR,
                                        }}
                                      />
                                    }
                                    sx={{
                                      fontSize: 16,
                                      px: 3,
                                      width: isXs ? "100%" : 125,
                                      minWidth: "90px",
                                      maxHeight: "48px",
                                      py: 1.3,
                                      // mb: 2,
                                      backgroundColor: "themeColor",
                                      borderRadius: 2.1,
                                      "&:hover": {
                                        backgroundColor: "themeColor", // Change to your theme color
                                      },
                                    }}
                                    disabled={storedVideoResponsesCount < questionStep}
                                    onClick={() => {
                                      stopRecording();
                                      setRecordingStatus("inactive");
                                      setLocalState({
                                        recordedVideoList: [...recordedVideoList, mediaBlobUrl],
                                      });
                                    }}
                                  >
                                    Stop
                                  </Button>
                                ) : null}
                                {quesStep < 4 ? (
                                  <Button
                                    variant="outlined"
                                    onClick={handleNext}
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={storedVideoResponsesCount === quesStep}
                                    sx={{
                                      borderColor: "themeColor",
                                      color: "black",
                                      fontWeight: "bold",
                                      borderRadius: 2,
                                      width: isXs ? "100%" : 100,
                                      fontSize: "15px",
                                      px: 1.4,
                                      py: 1.3,
                                      // mb: 2,
                                      "&:hover": {
                                        borderColor: "themeColor", // Change to your theme color
                                      },
                                    }}
                                  >
                                    {T.NEXT}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={handleSubmit}
                                    disabled={
                                      storedVideoResponsesCount < 5 || audioData?.status === "pending"
                                      // ||formSubmitted
                                    }
                                    sx={{
                                      borderColor: "themeColor",
                                      color: "black",
                                      fontWeight: "bold",
                                      borderRadius: 2,
                                      width: isXs ? "100%" : 100,
                                      fontSize: "15px",
                                      px: 1.4,
                                      py: 1.3,
                                      mb: 2,
                                      "&:hover": {
                                        backgroundColor: "themeColor", // Change to your theme color
                                        borderColor: NETSMARTZ_THEME_COLOR,
                                        color: "white",
                                      },
                                    }}
                                  >
                                    {T.SUBMIT}
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          );
                        }
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        }
        <Grid item xs={isMd ? 2 : 3.25} />
        {/* {openInstructionModal && quesStep === 0 && (
        <InstructionsModal
          openInstructionModal={openInstructionModal}
          micPermission={permission}
          handleInstructionModal={handleInstructionModal}
        />
      )} */}
      </Grid>
    </>
  );
};

export default InterviewQuestions;
