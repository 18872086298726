import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { MISCurrentUser } from "utils/validations";
import UploadIcon from "@mui/icons-material/Upload";
import AddLinkIcon from "@mui/icons-material/AddLink";
import DownloadIcon from "@mui/icons-material/Download";
import { TableRow, TableCell, Typography, TableBody, styled, Button, Tooltip, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import T from "T";
import { canEditCaseStudy } from "utils/permissions";
import { useIsPublishedCaseStudyMutation } from "api/CaseStudy/IsPublishedCaseStudy";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import MISNoData from "components/common/MISNoData";
import { StyledBox } from "utils/members";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import { useNavigate } from "react-router-dom";
import { downloadCaseStudyFile } from "../caseStudyModel";
import Constants from "Constants";
const { SERVER_URL } = Constants;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  "&:last-child": {
    minWidth: "120px",
  },
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));
const CaseStudyTableBody = ({
  allTableRows = [],
  onClickReminder = noop,
  refreshTable = noop,
  handleCaseStudyUploadLinkDialog = noop,
  handleOpenCaseStudyUpload = noop,
  handleAcceptCaseStudyDialog = noop,
}) => {
  const navigate = useNavigate();
  const [IsPublishedCaseStudy] = useIsPublishedCaseStudyMutation();
  const { user, sessionToken } = MISCurrentUser();

  const userRole = get(user, "role", "");

  // const publishCaseStudy = (csId) => {
  //   const payload = {
  //     isPublished: true,
  //   };
  //   IsPublishedCaseStudy({ payload, csId })
  //     .unwrap()
  //     .then((res) => {
  //       if (res.status === "OK") {
  //         refreshTable();
  //         toast.success(T.CASE_STUDY_PUBLISHED);
  //       } else {
  //         toast.error(T.REQUEST_NOT_FULFILLED);
  //       }
  //     })
  //     .catch(handleError);
  // };
  const extractFilename = (filePath) => {
    const fullFilename = filePath.substring(filePath.lastIndexOf("/") + 1);
    const [filenameWithHash, extension] = fullFilename.split(/(?=\.[^.]+$)/);
    const filename = filenameWithHash.split("_")[0];
    return filename + extension;
  };

  const downloadCaseStudy = async (id, type, file) => {
    const url = `${SERVER_URL}/caseStudy/download?caseStudyId=${id}&type=${type}`;
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      downloadCaseStudyFile(response, extractFilename(file));
      toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
    } catch (error) {
      handleError(error);
    }
  };

  const handleOpenCSVersions = (id) => {
    navigate(`/app/case-study/${id}/versions`);
  };

  const ensureHttps = (link) => {
    const regex = /^(https?:\/\/)/i;
    if (!regex.test(link)) {
      return `https://${link}`;
    }
    return link;
  };

  const openPublishLink = (publishedLink) => {
    if (publishedLink !== null) {
      window.open(ensureHttps(publishedLink), "_blank");
      return;
    }
    return toast.error("Link is not published yet");
  };
  const getColor = (status) => {
    if ([T.PUBLISHED, T.PMO_APPROVED].includes(status)) return "green";
    else if ([T.SUBMITTED_BY_PM, T.SUBMITTED_BY_MT].includes(status)) return NETSMARTZ_THEME_COLOR;
    else if ([T.PENDING].includes(status)) return "#E7AE2B";
    else return "red";
  };
  const downloadCondt = (role, status, type) => {
    if (type === T.PM) {
      if ([T.PM, T.MT, T.PMO].includes(role)) {
        return ![T.PENDING].includes(status);
      }
    } else if (type === T.MT) {
      if ([T.PM, T.MT, T.PMO].includes(role)) {
        return ![T.PENDING, T.SUBMITTED_BY_PM].includes(status);
      }
    }
  };
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const { caseStudyStatus } = record;
          const mtFile = get(record, "fileMT", "");
          const pmFile = get(record, "filePM", "");
          const publishedLink = get(record, "publishedLink", null);

          const checkDisableCondition = (statusArrays) => {
            const { pmStatus, mtStatus, canEditStatus } = statusArrays;
            if (userRole === T.PM && pmStatus.includes(caseStudyStatus)) return false;
            if (userRole === T.MT && mtStatus.includes(caseStudyStatus)) return false;
            if (canEditCaseStudy() && canEditStatus.includes(caseStudyStatus)) return false;

            return true;
          };
          const disableStatusArrays = {
            pmStatus: [T.PENDING, T.PMO_REJECTED, T.SUBMITTED_BY_PM],
            mtStatus: [T.SUBMITTED_BY_MT, T.SUBMITTED_BY_PM, T.PMO_APPROVED],
            canEditStatus: [T.SUBMITTED_BY_MT],
          };
          const newDisableStatusArrays = {
            pmStatus: [T.PENDING, T.PMO_REJECTED],
            mtStatus: [T.SUBMITTED_BY_PM, T.PMO_APPROVED],
            canEditStatus: [T.SUBMITTED_BY_MT],
          };
          const disableCondition = () => checkDisableCondition(disableStatusArrays);
          const newDisableCondition = () => checkDisableCondition(newDisableStatusArrays);

          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell
                sx={{
                  borderLeft: `${!newDisableCondition() ? "5px solid #40c55e" : ""}`,
                  borderRadius: "5px 0 0 5px",
                }}
              >
                {index + 1}.
              </StyledTableCell>
              <StyledTableCell>{get(record, "project.name", "")}</StyledTableCell>
              <StyledTableCell>{get(record, "project.projectFunctionalHead.name", "")}</StyledTableCell>
              <StyledTableCell>{get(record, "project.projectManager.name", "")}</StyledTableCell>
              <StyledTableCell>{get(record, "projectStartDate", "")}</StyledTableCell>
              <StyledTableCell>{get(record, "caseStudyDueDate", "")}</StyledTableCell>
              <StyledTableCell align="center">
                {caseStudyStatus === T.PMO_REJECTED ? (
                  <Tooltip title={get(record, "pmoComment", "")} placement="top">
                    <Typography sx={{ color: getColor(caseStudyStatus), fontSize: 14, cursor: "pointer" }}>
                      {get(record, "caseStudyStatus", "")}
                    </Typography>
                  </Tooltip>
                ) : caseStudyStatus === T.PUBLISHED ? (
                  <Typography
                    onClick={() => openPublishLink(publishedLink)}
                    sx={{ color: getColor(caseStudyStatus), fontSize: 14, cursor: "pointer", textDecoration: "underline" }}
                  >
                    {get(record, "caseStudyStatus", "")}
                  </Typography>
                ) : (
                  <Typography sx={{ color: getColor(caseStudyStatus), fontSize: 14 }}>
                    {get(record, "caseStudyStatus", "")}
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <label htmlFor={`fileOutput_${record.id}`}>
                  <Tooltip title={T.DOWNLOAD_FILE} placement="top">
                    <IconButton
                      disabled={!downloadCondt(userRole, caseStudyStatus, "PM")}
                      onClick={() => downloadCaseStudy(record.id, "PM", pmFile)}
                    >
                      <DownloadIcon
                        fontSize="small"
                        sx={{
                          mr: 1,
                          cursor: "pointer",
                          color: !downloadCondt(userRole, caseStudyStatus, "PM") ? "grey" : NETSMARTZ_THEME_COLOR,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </label>
              </StyledTableCell>
              <StyledTableCell align="center">
                <label htmlFor={`fileOutput_${record.id}`}>
                  <Tooltip title={T.DOWNLOAD_FILE} placement="top">
                    <IconButton
                      disabled={!downloadCondt(userRole, caseStudyStatus, "MT")}
                      onClick={() => downloadCaseStudy(record.id, "MT", mtFile)}
                    >
                      <DownloadIcon
                        fontSize="small"
                        sx={{
                          mr: 1,
                          cursor: "pointer",
                          color: !downloadCondt(userRole, caseStudyStatus, "MT") ? "grey" : NETSMARTZ_THEME_COLOR,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </label>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Grid>
                  {
                    <Tooltip title={T.VERSIONS_HISTORY} placement="top">
                      <IconButton
                        disabled={!get(record, "isHistoryPresent", false)}
                        onClick={() => handleOpenCSVersions(record.id)}
                      >
                        <BackupTableIcon
                          sx={{
                            cursor: "pointer",
                            color: !get(record, "isHistoryPresent", false) ? "grey" : NETSMARTZ_THEME_COLOR,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  }
                  {canEditCaseStudy() ? (
                    <>
                      <Tooltip title={T.ACCEPT} placement="top">
                        <IconButton
                          disabled={disableCondition()}
                          onClick={() => handleAcceptCaseStudyDialog(get(record, "id", ""), "accept")}
                          sx={{
                            pt: 1.25,
                            cursor: "pointer",
                            color: "green",
                          }}
                        >
                          <CheckIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={T.REJECT} placement="top">
                        <IconButton
                          disabled={disableCondition()}
                          onClick={() => handleOpenCaseStudyUpload(record.id)}
                          sx={{
                            pt: 1.25,
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          userRole === T.MT && get(record, "caseStudyStatus", "") === T.PMO_APPROVED
                            ? T.PUBLISH_CASE_STUDY
                            : userRole === T.MT && caseStudyStatus === T.SUBMITTED_BY_PM
                            ? T.ACCEPT
                            : T.UPLOAD_FILE
                        }
                        placement="top"
                      >
                        <IconButton
                          disabled={disableCondition()}
                          onClick={() =>
                            handleOpenCaseStudyUpload(
                              record.id,
                              userRole === T.MT && get(record, "caseStudyStatus", "") === T.PMO_APPROVED,
                            )
                          }
                          sx={{
                            pt: 1.25,
                            mx: userRole === T.MT && caseStudyStatus === T.SUBMITTED_BY_PM ? 0 : 1,
                            cursor: "pointer",
                            color: userRole === T.MT && caseStudyStatus === T.SUBMITTED_BY_PM ? "green" : NETSMARTZ_THEME_COLOR,
                          }}
                        >
                          {caseStudyStatus === T.PMO_APPROVED && [T.MT, T.KRA].includes(userRole) ? (
                            <AddLinkIcon fontSize="small" />
                          ) : userRole === T.MT && caseStudyStatus === T.SUBMITTED_BY_PM ? (
                            <CheckIcon fontSize="small" />
                          ) : (
                            <UploadIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                      {userRole === T.MT && caseStudyStatus === T.SUBMITTED_BY_PM && (
                        <Tooltip title={T.REJECT} placement="top">
                          <IconButton
                            disabled={disableCondition()}
                            onClick={() =>
                              handleOpenCaseStudyUpload(
                                record.id,
                                userRole === T.MT && get(record, "caseStudyStatus", "") === T.PMO_APPROVED,
                                "reject",
                              )
                            }
                            sx={{
                              pt: 1.25,
                              cursor: "pointer",
                              color: "red",
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

CaseStudyTableBody.propTypes = {
  allTableRows: PropTypes.array,
  pName: PropTypes.string,
  refreshTable: PropTypes.func,
  handleAcceptCaseStudyDialog: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  onClickReminder: PropTypes.func,
};

export default memo(CaseStudyTableBody);
